import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faCircle} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Badge, Image, Tab, Table, Nav} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link} from "sancho";
import Loading from '../components/Loading';
import BookingMessageList from '../components/bookingDetailPage/BookingMessageList';
import Auth from '../Auth/Auth';

import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import _findIndex from 'lodash/findIndex';

import {isEmail} from '../util/validation';
import {prettySingleDate, prettyDateTime} from '../util/transformDate';
import {status} from '../util/constances';
import {getBookingDetail, resendVoucher, cancelBooking, retrieveBkgHistory,
  getBookingMessage,
  createBookingMessage,
  readBookingMessage,} from '../actions/booking';

const auth = new Auth();

class BookingDetailPage extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onResendClick = this.onResendClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);

    this.state = {
      resendEmail: '',
      emailError: false,
      emailSuccess: false,
      tabIndex: 0,
      note: '',
    };
  }

  componentDidMount() {
    this.props.getBookingDetail(this.props.match.params.id)
  }

  componentWillUnmount() {
    // this.props.clearProductDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resendVoucherTimestamp !== this.props.resendVoucherTimestamp) {
      this.setSendEmailSuccess()
    }
    if (!prevProps.shouldRefreshBooking && this.props.shouldRefreshBooking) {
      this.props.getBookingDetail(this.props.match.params.id)
    }
    if (!prevProps.shouldRefreshBookingMessage && this.props.shouldRefreshBookingMessage) {
      this.props.getBookingMessage(this.props.bookingDetail.productSySID)
      // this.props.getUnreadBookingMessage()
    }
  }

  onFieldChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onResendClick() {
    if (isEmail(this.state.resendEmail)) {
      this.setState({
        emailError: false,
        emailSuccess: false,
      })
      this.props.resendVoucher(this.props.bookingDetail.productSySID, this.state.resendEmail)
    } else {
      this.setState({
        emailError: true,
        emailSuccess: false
      })
    }
  }

  onCancelClick() {
    this.props.cancelBooking(this.props.bookingDetail.productSySID);
  }

  onTabSelect(eventKey) {
    if (eventKey === 'history' && !this.props.historyMsg) {
      this.props.retrieveBkgHistory(this.props.bookingDetail.productSySID)
    }

    if (eventKey === 'message' && !this.props.bookingMessage) {
      this.props.getBookingMessage(this.props.bookingDetail.productSySID)
    }
  }

  setSendEmailSuccess() {
    this.setState({
      emailSuccess: true
    })
  }

  render () {
    const bDetail = this.props.bookingDetail

    const hasUnreadMsg = this.props.myUnreadBookingMessage && bDetail && this.props.myUnreadBookingMessage.find(r =>(
      r.ProductSysID === bDetail.productSySID &&
      (r.TypeCode !== auth.getCompanyCode() ||
      r.Login !== auth.getUserCode())
    ))

    return (
      <div>
        <title>Booking Detail {bDetail && `#${bDetail.productSySID} ${bDetail.activityName}`} | Product Booking System</title>
        {bDetail &&
          <div className="container">

            <Breadcrumbs className="mt-1 mb-3">
              <BreadcrumbItem>
                <Link href="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link href={'/booking-history'}>Booking History</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>#{bDetail.productSySID} {bDetail.activityName}</BreadcrumbItem>
            </Breadcrumbs>

            <h1>Booking Detail</h1>
            <p>Hello, <b>{bDetail.email}</b>. Your reservation for <b>{bDetail.activityName}</b> is created.</p>
            <p>Be sure to check below for instructions on how to use attached e-voucher. For more details, kindly refer to the attachment.</p>


            <Row className="justify-content-center">
              <Col lg="4">
                <div className="green-bg-box">
                  {bDetail.productDetail && bDetail.productDetail.images &&
                    <Image src={bDetail.productDetail.images[0].url} className="w-100 mb-2" />
                  }


                  <div className="text-muted">Order ID</div>
                  <div className="font-weight-bold mb-3">{bDetail.productSySID}</div>

                  {bDetail.engineRef &&
                    <div>
                      <div className="text-muted">Engine Ref</div>
                      <div className="font-weight-bold mb-3">{bDetail.engineRef}</div>

                    </div>
                  }

                  <div className="text-muted">Status</div>
                  <div className="font-weight-bold mb-3 h5">{_find(status, sta => sta.code === bDetail.bookingStatus) ? <Badge variant={_find(status, sta => sta.code === bDetail.bookingStatus).variant}>{_find(status, sta => sta.code === bDetail.bookingStatus).name}</Badge> : bDetail.bookingStatus}</div>

                  <div className="text-muted">Booking created on </div>
                  <div className="font-weight-bold mb-3">{prettyDateTime(bDetail.createOn)}</div>
                </div>
              </Col>
              <Col lg="8">
                <Tab.Container defaultActiveKey="detail">
                  <Nav variant="tabs" onSelect={selectedKey => this.onTabSelect(selectedKey)}>
                    <Nav.Item><Nav.Link eventKey="detail">Detail</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="history">History</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="message">Message {hasUnreadMsg && <FontAwesomeIcon icon={faCircle} className="red" size="xs" />}</Nav.Link></Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="detail" className="pt-2">
                      <Row>
                        <Col>
                          <div className="mb-5">
                            <div className="product-info__title">Booking Information</div>
                            <div className="mb-3">
                              <div className="text-muted">Product</div>
                              <div className="font-weight-bold"><Link href={`/activity/${bDetail.activityID}`} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />{bDetail.activityName}</Link></div>
                            </div>

                            <div className="mb-3">
                              <div className="text-muted">Item</div>
                              <div className="font-weight-bold">{bDetail.productName}</div>
                            </div>

                            <Row className="mb-3">
                              <Col>
                                <div className="text-muted">Travel Date</div>
                                <div className="font-weight-bold">{prettySingleDate(bDetail.requestDate)}</div>
                              </Col>
                              {bDetail.timeslot !== "00:00" &&
                                <Col>
                                  <div className="text-muted">Timeslot</div>
                                  <div className="font-weight-bold">{bDetail.timeslot}</div>
                                </Col>
                              }
                            </Row>

                            <div className="mb-3">
                              <Row >
                                <Col xs="4"><div className="text-muted">Quantity</div></Col>
                                <Col xs="2"><div className="text-muted">Single Price</div></Col>
                                <Col xs="2"><div className="text-muted">Status</div></Col>
                                <Col xs="2"><div className="text-muted">Item Ref</div></Col>
                                <Col xs="2"></Col>
                              </Row>
                              {bDetail.bookings.map((bk, i) =>
                                <Row key={i} className="mb-1 font-weight-bold">
                                  <Col xs="4">{bk.quantity} x {bk.skuName}</Col>
                                  <Col xs="2">${bk.sellTtlAmt}</Col>
                                  <Col xs="2">{bk.itemStatus}</Col>
                                  <Col xs="2">{bk.itemRef ? bk.itemRef : '-'}</Col>
                                  <Col xs="2">{bk.voucherUrl && <Link href={bk.voucherUrl} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />Voucher</Link>}</Col>
                                </Row>
                              )}
                            </div>

                            <div>
                              <div className="text-muted">Total Sell</div>
                              <div className="font-weight-bold text-lg text-info">{bDetail.sellCurrency} ${bDetail.sellTtlAmt}</div>
                            </div>
                          </div>

                          {(bDetail.extraInfo.general.length > 0 || bDetail.extraInfo.travelers.length > 0) &&
                            <div className="mb-5">
                              <div className="product-info__title">Extra Information</div>
                              {bDetail.extraInfo.general.length > 0 &&
                                <div className="mb-3">
                                  <div className="text-muted">General Info</div>
                                  <Table bordered>
                                    <tbody>
                                      {bDetail.extraInfo.general.map((n, i) =>
                                        <tr key={i}>
                                          <td>{n.remark1}</td>
                                          <td className="font-weight-bold">{n.value}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              }
                              {bDetail.extraInfo.travelers.length > 0 &&
                                <div>
                                  <div className="text-muted">Traveler Info</div>
                                  <Table bordered>
                                    <thead>
                                      <tr>
                                        <th className="font-weight-normal">#</th>
                                        {bDetail.extraInfo.travelers[0].Info.map((info, i) =>
                                          <th className="font-weight-normal" key={i}>{info.remark1}</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bDetail.extraInfo.travelers.map((n, i) =>
                                        <tr key={i}>
                                          <td>{i+1}</td>
                                          {n.Info.map(input =>
                                            <td key={input.typeId} className="font-weight-bold">{input.value}</td>
                                          )}
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              }
                            </div>
                          }

                          {bDetail.productDetail && bDetail.productDetail.guidelinesObj && (_findIndex(Object.values(bDetail.productDetail.guidelinesObj), v => v.length > 0) !== -1) &&
                            <div className="mb-5">
                              <div className="product-info__title">Activity Guidelines</div>

                              {Object.keys(bDetail.productDetail.guidelinesObj).map(key =>
                                bDetail.productDetail.guidelinesObj[key] &&
                                <div key={key} className="mb-2">
                                  <div className="text-muted">{_startCase(key)}</div>
                                  <ReactMarkdown
                                    source={bDetail.productDetail.guidelinesObj[key]}
                                    escapeHtml={false}
                                    key={key}
                                    className="product-info"
                                  />
                                </div>
                              )}
                            </div>
                          }

                          {bDetail.productDetail && bDetail.productDetail.usageObj && (_findIndex(Object.values(bDetail.productDetail.usageObj), v => v.length > 0) !== -1) &&
                            <div className="mb-5">
                              <div className="product-info__title">Usage</div>

                              {Object.keys(bDetail.productDetail.usageObj).map(key =>
                                bDetail.productDetail.usageObj[key] &&
                                <div key={key} className="mb-2">
                                  <div className="text-muted">{_startCase(key)}</div>
                                  <ReactMarkdown
                                    source={bDetail.productDetail.usageObj[key]}
                                    escapeHtml={false}
                                    key={key}
                                    className="product-info"
                                  />
                                </div>
                              )}
                            </div>
                          }

                          {bDetail.itemDetail && (_findIndex(Object.values(bDetail.itemDetail), v => v.length > 0) !== -1) &&
                            <div className="mb-5">
                              <div className="product-info__title">Item Detail</div>

                              {bDetail.itemDetail.detail !== '' &&
                                <div className="mb-2">
                                  <div className="text-muted">Detail</div>
                                  <div>{bDetail.itemDetail.detail}</div>
                                </div>
                              }

                              {bDetail.itemDetail.voucherUsage !== '' &&
                                <div className="mb-2">
                                  <div className="text-muted">Voucher Usage</div>
                                  <div>{bDetail.itemDetail.voucherUsage}</div>
                                </div>
                              }

                              {bDetail.itemDetail.voucherTypeDesc !== '' &&
                                <div className="mb-2">
                                  <div className="text-muted">Voucher Type Description</div>
                                  <div>{bDetail.itemDetail.voucherTypeDesc}</div>
                                </div>
                              }

                              {bDetail.itemDetail.confirmationType !== '' &&
                                <div className="mb-2">
                                  <div className="text-muted">Confirmation Type</div>
                                  <div>{bDetail.itemDetail.confirmationType}</div>
                                </div>
                              }

                              {bDetail.itemDetail.confirmationDetails !== '' &&
                                <div className="mb-2">
                                  <div className="text-muted">Confirmation Details</div>
                                  <div>{bDetail.itemDetail.confirmationDetails}</div>
                                </div>
                              }
                            </div>
                          }

                          {bDetail.productDetail && bDetail.productDetail.termsAndConditions &&
                            <div className="mb-4">
                              <div className="product-info__title">Terms and Condition</div>
                              <ReactMarkdown
                                source={bDetail.productDetail.termsAndConditions}
                                escapeHtml={false}
                                className="product-info"
                              />
                            </div>
                          }


                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="history" className="pt-2">
                      <table className="table table-bordered mt-2 mb-5 table-striped relative">
              					<thead>
              						<tr>
              							<th width="24">#</th>
              							<th width="200">Date</th>
              							<th>Notes</th>
              						</tr>
              					</thead>
              					<tbody>
              						{this.props.historyMsg && this.props.historyMsg.map((history, i) =>
                            <tr key={i}>
                             <td>{this.props.historyMsg.length - i}</td>
                             <td>{history.createon}</td>
                             <td>{history.notes}</td>
                           </tr>
              						)}
                          {!this.props.historyMsg &&
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No booking history.
                                  {this.props.componentLoading && <Loading isAbsolute />}
                                </td>
                              </tr>
                            }
              					</tbody>
              				</table>

                    </Tab.Pane>
                    <Tab.Pane eventKey="message" className="pt-2">
                      <BookingMessageList
                        htlMessages={this.props.bookingMessage}
                        handleCreateMsg={this.props.createBookingMessage}
                        handleReadMsg={this.props.readBookingMessage}
                        orderId={this.props.bookingDetail.productSySID}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>


          </div>
        }
        {!bDetail && <div className="pt-30vh text-center">No Detail.</div>}
      </div>
    );
  }
}


BookingDetailPage.propTypes = {
	bookingID: PropTypes.string,
	bookingDetail: PropTypes.object,
	resendVoucherTimestamp: PropTypes.number,
	shouldRefreshBooking: PropTypes.bool,
	historyMsg: PropTypes.array,
	shouldGetHistory: PropTypes.bool,
	bookingMessage: PropTypes.array,
  shouldRefreshBookingMessage: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    bookingID: state.booking.bookingID,
    bookingDetail: state.booking.bookingDetail,
    resendVoucherTimestamp: state.booking.resendVoucherTimestamp,
    shouldRefreshBooking: state.booking.shouldRefreshBooking,
    historyMsg: state.booking.historyMsg,
    shouldGetHistory: state.booking.shouldGetHistory,
    bookingMessage: state.booking.bookingMessage,
    shouldRefreshBookingMessage: state.booking.shouldRefreshBookingMessage,
    myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
	}
}

const mapDispatchToProps = {
  getBookingDetail,
  resendVoucher,
  cancelBooking,
  retrieveBkgHistory,
  getBookingMessage,
  createBookingMessage,
  readBookingMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailPage);
