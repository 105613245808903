import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	pageLoading: false,
	hotProductLoading: false,
	hotLocationLoading: false
};

const loading = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.LOGIN_REQUEST:
	case ActionTypes.GET_PRODUCT_DETAIL_REQUEST:
	case ActionTypes.GET_PRODUCT_TIMESLOT_REQUEST:
	case ActionTypes.GET_PRODUCT_SKU_REQUEST:
	case ActionTypes.CREATE_BOOKING_REQUEST:
	case ActionTypes.GET_BOOKING_DETAIL_REQUEST:
	case ActionTypes.GET_BOOKING_HISTORY_REQUEST:
	case ActionTypes.GET_ALL_LOCATION_REQUEST:
	case ActionTypes.GET_PRODUCT_BY_CITY_REQUEST:
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_REQUEST:
	case ActionTypes.RESEND_VOUCHER_REQUEST:
	case ActionTypes.CANCEL_BOOKING_REQUEST:
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_REQUEST:
	case ActionTypes.ADD_BOOKING_HISTORY_REQUEST:
	case ActionTypes.GET_BOOKING_MESSAGE_REQUEST:
	case ActionTypes.CREATE_BOOKING_MESSAGE_REQUEST:
	case ActionTypes.READ_BOOKING_MESSAGE_REQUEST:
		return Object.assign({}, state, {
			pageLoading: true
		});
	case ActionTypes.LOGIN_SUCCESS:
	case ActionTypes.LOGIN_FAILURE:
	case ActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_TIMESLOT_SUCCESS:
	case ActionTypes.GET_PRODUCT_TIMESLOT_FAILURE:
	case ActionTypes.GET_PRODUCT_SKU_SUCCESS:
	case ActionTypes.GET_PRODUCT_SKU_FAILURE:
	case ActionTypes.CREATE_BOOKING_SUCCESS:
	case ActionTypes.CREATE_BOOKING_FAILURE:
	case ActionTypes.GET_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.GET_BOOKING_DETAIL_FAILURE:
	case ActionTypes.GET_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.GET_BOOKING_HISTORY_FAILURE:
	case ActionTypes.GET_ALL_LOCATION_SUCCESS:
	case ActionTypes.GET_ALL_LOCATION_FAILURE:
	case ActionTypes.GET_PRODUCT_BY_CITY_SUCCESS:
	case ActionTypes.GET_PRODUCT_BY_CITY_FAILURE:
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_SUCCESS:
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_FAILURE:
	case ActionTypes.RESEND_VOUCHER_SUCCESS:
	case ActionTypes.RESEND_VOUCHER_FAILURE:
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
	case ActionTypes.CANCEL_BOOKING_FAILURE:
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_FAILURE:
	case ActionTypes.ADD_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.ADD_BOOKING_HISTORY_FAILURE:
	case ActionTypes.GET_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.GET_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.READ_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.READ_BOOKING_MESSAGE_FAILURE:
		return Object.assign({}, state, {
			pageLoading: false
		});
	case ActionTypes.GET_HOT_PRODUCT_REQUEST:
		return Object.assign({}, state, {
			hotProductLoading: true
		});
	case ActionTypes.GET_HOT_PRODUCT_SUCCESS:
	case ActionTypes.GET_HOT_PRODUCT_FAILURE:
		return Object.assign({}, state, {
			hotProductLoading: false
		});
	case ActionTypes.GET_HOT_LOCATION_REQUEST:
		return Object.assign({}, state, {
			hotLocationLoading: true
		});
	case ActionTypes.GET_HOT_LOCATION_SUCCESS:
	case ActionTypes.GET_HOT_LOCATION_FAILURE:
		return Object.assign({}, state, {
			hotLocationLoading: false
		});
	default:
		return state;
	}
};

export default loading;
