import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import Sticky from 'react-stickynode';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faFeather} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Carousel, Button, ButtonGroup, Form, Table} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link, IconMapPin, IconChevronLeft, IconChevronRight, IconUsers, IconClock, IconTruck, IconGrid} from "sancho";
import cogoToast from 'cogo-toast';
import Auth from '../Auth/Auth';

import CustomDatePicker from '../components/CustomDatePicker';
import AlertText from '../components/AlertText';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';

import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _set from 'lodash/set';
import _sumBy from 'lodash/sumBy';
import _round from 'lodash/round';
import _toNumber from 'lodash/toNumber';
import _extend from 'lodash/extend';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';

import {dateFormatToSend} from '../util/transformDate';
import {getProductDetail, getProductTimeSlot, getProductSkuPrice, clearProductDetail, createBooking} from '../actions/product';

const auth = new Auth();

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);

    this.onDateChange = this.onDateChange.bind(this);
    this.onProductIdClcik = this.onProductIdClcik.bind(this);
    this.onMinusClick = this.onMinusClick.bind(this);
    this.onPlusClick = this.onPlusClick.bind(this);
    this.onExtraGeneralInfoFieldChange = this.onExtraGeneralInfoFieldChange.bind(this);
    this.onExtraTravelerInfoFieldChange = this.onExtraTravelerInfoFieldChange.bind(this);
    this.onExtraGeneralInfoDateChange = this.onExtraGeneralInfoDateChange.bind(this);
    this.onExtraTravelerInfoDateChange = this.onExtraTravelerInfoDateChange.bind(this);
    this.onBookClick = this.onBookClick.bind(this);
    this.creatBooking = this.creatBooking.bind(this);

    this.state = {
      selectedDate: '',
      selectedProductId: '',
      selectedTimeslot: '',
      booksku: null,
      extraInfo: {},
      totalPrice: 0,
      timeSlotError: false,
      extraInfoError: false,
      quantityError: false,
      totalQuantity: 0,
      isModalUp: false
    };
  }

  componentDidMount() {
    this.props.getProductDetail(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearProductDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.productDetail && this.props.productDetail) {
      if (this.props.productDetail.itemList.length === 1) {
        this.onProductIdClcik(this.props.productDetail.itemList[0].productId)
      }
    }

    if (this.state.selectedDate && this.state.selectedProductId) {
      if ((prevState.selectedDate !== this.state.selectedDate)
      || (prevState.selectedProductId !== this.state.selectedProductId)) {
        this.props.getProductTimeSlot(
          this.props.productDetail.activityId,
          this.state.selectedProductId,
          dateFormatToSend(this.state.selectedDate)
        )
      }
    }

    if (!prevProps.productTimeSlot && this.props.productTimeSlot && this.props.productTimeSlot.length === 1) {
      this.onTimeslotClcik(this.props.productTimeSlot[0])
    }

    if (prevProps.timeSlotTimeStamp !== this.props.timeSlotTimeStamp) {
      this.props.getProductSkuPrice(
        this.props.productDetail.activityId,
        this.state.selectedProductId,
        dateFormatToSend(this.state.selectedDate)
      )
    }

    if (prevProps.productSkuPrice !== this.props.productSkuPrice) {
      this.setSku()
    }

    if (prevState.totalQuantity !== this.state.totalQuantity) {
      this.calculateTotalPrice()
    }

    if (!prevProps.bookingID && this.props.bookingID) {
      window.location.href = `/booking/${this.props.bookingID}`;
    }

  }

  onProductIdClcik(id) {
    const selectedProduct = _find(this.props.productDetail.itemList, {productId: id})

    const hasExtraGeneralInfo = selectedProduct.extraInfos.general.length > 0
    const hasExtraTravellerInfo = selectedProduct.extraInfos.travelers.length > 0

    let travelersInfo = []
    if (hasExtraTravellerInfo) {
      travelersInfo = selectedProduct.extraInfos.travelers.map(info => ({
        "typeId": info.infoId,
        "value": "",
        "isrequired": info.isrequired
      }))
    }

    this.setState({
      selectedProductId: id,
      extraInfo: {
        general: hasExtraGeneralInfo ? selectedProduct.extraInfos.general.map(el => ({
          "typeId": el.infoId,
          "value": "",
          "isrequired": el.isrequired
        })) : null,
        travelers: hasExtraTravellerInfo ? [{
          "travelerSeq": 1,
          "info": travelersInfo
        }] : null
      }
    })
  }

  onDateChange(date) {
    this.setState({
      selectedDate: dateFormatToSend(date)
    })
  }

  onTimeslotClcik(time) {
    this.setState({
      selectedTimeslot: time
    })
  }

  onMinusClick(skuId) {
    const cloneDeep = _cloneDeep(this.state.booksku)

    if (_find(cloneDeep, {skuId: skuId}) && _find(cloneDeep, {skuId: skuId}).qty !== 0) {
      _set(cloneDeep, [_findIndex(cloneDeep, {skuId: skuId}), 'qty'], _find(cloneDeep, {skuId: skuId}).qty - 1)

      const selectedProduct = _find(this.props.productDetail.itemList, {productId: this.state.selectedProductId})
      const hasExtraTravellerInfo = selectedProduct.extraInfos.travelers.length > 0

      const totalQuantity = _sumBy(cloneDeep, sku => sku.qty)

      this.setState({
        booksku: cloneDeep,
        totalQuantity: totalQuantity
      })

      if (hasExtraTravellerInfo) {
        this.deleteTraveler()
      }
    }
  }

  onPlusClick(skuId) {
    const cloneDeep = _cloneDeep(this.state.booksku)

    const selectedProduct = _find(this.props.productDetail.itemList, {productId: this.state.selectedProductId})
    const hasExtraTravellerInfo = selectedProduct.extraInfos.travelers.length > 0

    _set(cloneDeep, [_findIndex(cloneDeep, {skuId: skuId}), 'qty'], _find(cloneDeep, {skuId: skuId}).qty + 1)

    const totalQuantity = _sumBy(cloneDeep, sku => sku.qty)

    this.setState({
      booksku: cloneDeep,
      totalQuantity: totalQuantity
    })

    if (hasExtraTravellerInfo) {
      this.addTraveler()
    }
  }

  onExtraGeneralInfoFieldChange(e) {
    const id = e.target.name
    const cloneDeep = _cloneDeep(this.state.extraInfo)

    _set(cloneDeep, ['general', _findIndex(cloneDeep.general, {typeId: id}), 'value'], e.target.value)

    this.setState({
      extraInfo: cloneDeep
    })
  }

  onExtraTravelerInfoFieldChange(e, i) {
    const id = e.target.name
    const cloneDeep = _cloneDeep(this.state.extraInfo)
    const theTypeIndex = _findIndex(cloneDeep.travelers[i].info, {typeId: id})

    _set(cloneDeep, ['travelers', i, 'info', theTypeIndex, 'value'], e.target.value)

    this.setState({
      extraInfo: cloneDeep
    })

  }

  onExtraGeneralInfoDateChange(date, name, i) {
    const id = name
    const cloneDeep = _cloneDeep(this.state.extraInfo)

    _set(cloneDeep, ['general', _findIndex(cloneDeep.general, {typeId: id}), 'value'], dateFormatToSend(date))

    this.setState({
      extraInfo: cloneDeep
    })
  }

  onExtraTravelerInfoDateChange(date, name, i) {
    const id = name
    const cloneDeep = _cloneDeep(this.state.extraInfo)
    const theTypeIndex = _findIndex(cloneDeep.travelers[i].info, {typeId: id})

    _set(cloneDeep, ['travelers', i, 'info', theTypeIndex, 'value'], dateFormatToSend(date))

    this.setState({
      extraInfo: cloneDeep
    })

  }

  onBookClick() {
    let isValid = true
    let hasTimeslot = true
    let isExtraInfoValid = true
    let hasTotalPrice = this.state.totalPrice !== 0
    const isReachMinPax = !_filter(this.state.booksku, sku => sku.qty < _find(this.props.productSkuPrice.skuList, {skuID: sku.skuId}).sku_min_pax).length > 0

    const selectedProduct = this.state.selectedProductId && _find(this.props.productDetail.itemList, {productId: this.state.selectedProductId})

    const hasExtraGeneralInfo = selectedProduct.extraInfos.general.length > 0
    const hasExtraTravellerInfo = selectedProduct.extraInfos.travelers.length > 0

    if (hasExtraGeneralInfo && !hasExtraTravellerInfo) {
      isExtraInfoValid = !_find(this.state.extraInfo.general, {isrequired: true, value: ""})
    } else if (hasExtraTravellerInfo && !hasExtraGeneralInfo) {
      isExtraInfoValid = !this.state.extraInfo.travelers.find(n => _find(n.info, {isrequired: true, value: ""}))
    } else if (hasExtraGeneralInfo && hasExtraTravellerInfo) {
      isExtraInfoValid = !_find(this.state.extraInfo.general, {isrequired: true, value: ""}) && !this.state.extraInfo.travelers.find(n => _find(n.info, {isrequired: true, value: ""}))
    }

    if (this.props.productTimeSlot && this.props.productTimeSlot[0] !== '00:00') {
      hasTimeslot = !!this.state.selectedTimeslot
    }

    isValid = hasTimeslot && isExtraInfoValid && hasTotalPrice && isReachMinPax

    if (!isValid) {
      this.setState({
        timeSlotError: !hasTimeslot,
        extraInfoError: !isExtraInfoValid,
        quantityError: this.state.totalPrice === 0 || !isReachMinPax
      })
      cogoToast.error('Please fill in all cretiria.')

    } else {
      this.setState({
        timeSlotError: false,
        extraInfoError: false,
        quantityError: false,
        isModalUp: true
      })
    }
  }

  creatBooking() {
    this.props.createBooking(
      this.props.productDetail.activityId,
      this.state.selectedProductId,
      _filter(this.state.booksku, sku => sku.qty > 0),
      this.state.selectedDate,
      this.state.selectedTimeslot,
      this.state.extraInfo,
      this.state.totalPrice,
      this.props.productSkuPrice.sellCurrency
    )
  }

  calculateTotalPrice() {
    let totalPrice = 0
    this.state.booksku.map(sku => (
      totalPrice = totalPrice + (_find(this.props.productSkuPrice.skuList, {skuID: sku.skuId}).sellPrice * sku.qty)
    ))

    this.setState({
      totalPrice: _round(totalPrice, 2)
    })
  }

  addTraveler() {
    const totalQuantity = _sumBy(this.state.booksku, sku => sku.qty)
    const cloneDeepExtraInfo = _cloneDeep(this.state.extraInfo.travelers)

    const selectedProduct = _find(this.props.productDetail.itemList, {productId: this.state.selectedProductId})

    if (totalQuantity + 1 !== 1) {
      this.setState({
        extraInfo: _extend(this.state.extraInfo, {
          travelers: cloneDeepExtraInfo.concat({
            travelerSeq: this.state.extraInfo.travelers.length + 1,
            info: selectedProduct.extraInfos.travelers.map(info => ({
              "typeId": info.infoId,
              "value": "",
              "isrequired": info.isrequired
            }))
          })
        })
      })
    }
  }

  deleteTraveler() {
    const totalQuantity = _sumBy(this.state.booksku, sku => sku.qty)
    const cloneDeepExtraInfo = _cloneDeep(this.state.extraInfo.travelers)
    const selectedProduct = _find(this.props.productDetail.itemList, {productId: this.state.selectedProductId})

    if (totalQuantity !== 1) {
      this.setState({
        extraInfo: _extend(this.state.extraInfo, {
          travelers: cloneDeepExtraInfo.slice(0, -1)
        })
      })
    } else{
      this.setState({
        extraInfo: _extend(this.state.extraInfo, {
          travelers: [{"travelerSeq": 1,
                       "info": selectedProduct.extraInfos.travelers.map(info => ({
                          "typeId": info.infoId,
                          "value": "",
                          "isrequired": info.isrequired
                        }))
                      }]
        })
      })
    }
  }

  setSku() {
    this.setState({
      booksku: this.props.productSkuPrice.skuList.map(sku => ({skuId: sku.skuID, qty: 0})),
      totalPrice: 0,
      totalQuantity: 0
    })
  }

  getCarousel() {
    return (
      <Carousel className="product__carousel" prevIcon={<IconChevronLeft />} nextIcon={<IconChevronRight />}>
        {this.props.productDetail.images.map((img, i) =>
          <Carousel.Item key={i}>
            <div className="slider" style={{backgroundImage: `url(${img.url})`}} />
          </Carousel.Item>
        )}
      </Carousel>
    )
  }

  getExtraInfoInputType(input, i) {
    let isGeneral
    if (input.infoType === "general") { isGeneral = true } else { isGeneral = false }
    if (input.infovalue === "text" || input.infovalue === "string" || input.infovalue === "date") {
      return (
        <Form.Control
          type="text"
          autoComplete="off"
          name={input.infoId}
          onChange={(e) => isGeneral ? this.onExtraGeneralInfoFieldChange(e) : this.onExtraTravelerInfoFieldChange(e, i)}
          value={isGeneral ? _find(this.state.extraInfo.general, {typeId: input.infoId}).value : _find(this.state.extraInfo.travelers[i].info, {typeId: input.infoId}).value}
          placeholder={input.infoRemark2} />
      )
    } else if (input.infovalue === "list") {
      return (
        <Form.Control
          as="select"
          autoComplete="off"
          name={input.infoId}
          onChange={(e) => isGeneral ? this.onExtraGeneralInfoFieldChange(e) : this.onExtraTravelerInfoFieldChange(e, i)}
          value={isGeneral ? _find(this.state.extraInfo.general, {typeId: input.infoId}).value : _find(this.state.extraInfo.travelers[i].info, {typeId: input.infoId}).value}
        >
            <option value="" className="disabled">{input.infoRemark2}</option>
            {input.options.map((op, i) => <option value={op.key} key={op.key}>{op.value}</option>)}
        </Form.Control>
      )
    } else {
      return 'missing input: ' + input.infoRemark1
    }
  }

  render () {
    const pDetail = this.props.productDetail
    const hasTimeslot = this.props.productTimeSlot && this.props.productTimeSlot.length > 0 && this.props.productTimeSlot[0] !== '00:00'

    const selectedProduct = this.state.selectedProductId && _find(pDetail.itemList, {productId: this.state.selectedProductId})

    const hasExtraInfo = this.state.selectedProductId && selectedProduct.extraInfos.general.length + selectedProduct.extraInfos.travelers.length > 0

    const totalQuantity = _sumBy(this.state.booksku, sku => sku.qty)
    return (
      <div>
        <title>{pDetail && `${pDetail.title} |`} Product Booking System</title>
        {pDetail &&
          <div>
            {this.props.productDetail.images && this.props.productDetail.images.length > 0 && this.getCarousel()}
            <div className="container">
              <Breadcrumbs className="mt-1 mb-3">
                <BreadcrumbItem>
                  <Link href="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link href={`/location/${pDetail.countryId}`}>{_capitalize(pDetail.country)}</Link>
                </BreadcrumbItem>
                {pDetail.city ?
                  <BreadcrumbItem>
                    <Link href={`/location/${pDetail.countryId}/${_lowerCase(pDetail.cityId)}`}>{_capitalize(pDetail.city)}</Link>
                  </BreadcrumbItem> :
                  <BreadcrumbItem>{pDetail.title}</BreadcrumbItem>
                }
                {pDetail.city &&
                <BreadcrumbItem>{pDetail.title}</BreadcrumbItem>}
              </Breadcrumbs>

              <Row>
                <Col lg="8">
                  <h1 className="text-primary">{pDetail.title}</h1>
                  <h5 className="text-primary-dark">{pDetail.subtitle}</h5>
                  <div className="mb-4">
                    <div className="inline" title="City"><IconMapPin /> {pDetail.city}</div>
                    {pDetail.travelersNotes && pDetail.travelersNotes !== '' && <div className="inline ml-3" title="Travelers Notes"><IconUsers /> {pDetail.travelersNotes}</div>}
                    {pDetail.category && pDetail.category !== '' && <div className="inline ml-3" title="Category"><IconGrid /> {pDetail.category}</div>}
                    {pDetail.duration && pDetail.duration !== '' && <div className="inline ml-3" title="Duration"><IconClock /> {pDetail.duration}</div>}
                    {pDetail.transport && pDetail.transport !== '' && <div className="inline ml-3" title="Transport"><IconTruck /> {pDetail.transport}</div>}
                  </div>

                  {pDetail.guidelinesObj && (_findIndex(Object.values(pDetail.guidelinesObj), v => v.length > 0) !== -1) &&
                    <div className="mb-4">
                      <div className="product-info__title">Guidelines</div>
                      {Object.keys(pDetail.guidelinesObj).map(key =>
                        pDetail.guidelinesObj[key] &&
                        <div className="mt-2" key={key}>
                          <h6 className="product__option-title">{_startCase(key)}</h6>
                          <ReactMarkdown
                            source={pDetail.guidelinesObj[key]}
                            escapeHtml={false}
                            key={key}
                            className="product-info"
                          />
                        </div>
                      )}
                    </div>
                  }

                  {pDetail.usageObj && (_findIndex(Object.values(pDetail.usageObj), v => v.length > 0) !== -1) &&
                    <div className="mb-4">
                      <div className="product-info__title">Activity Usage</div>
                      {Object.keys(pDetail.usageObj).map(key =>
                        pDetail.usageObj[key] &&
                        <div className="mt-2" key={key}>
                          <h6 className="product__option-title">{_startCase(key)}</h6>
                          <ReactMarkdown
                            source={pDetail.usageObj[key]}
                            escapeHtml={false}
                            key={key}
                            className="product-info"
                          />
                        </div>
                      )}
                    </div>
                  }

                  {pDetail.termsAndConditions !== '' &&
                    <div className="mb-4">
                      <div className="product-info__title">Terms and Condition</div>
                      <div>
                        <ReactMarkdown
                          source={pDetail.termsAndConditions}
                          escapeHtml={false}
                          className="product-info"
                        />
                      </div>
                    </div>
                  }

                  <div className="p-4 bg-green rounded">
                    <div className="product-info__title">Item Options</div>

                    <div className="pb-3">
                      <div className="product__option-title">
                        Item
                      </div>
                      <div>
                        {pDetail.itemList.map(prod =>
                          <Button className={`mr-2 mb-2 ${this.state.selectedProductId === prod.productId ? 'active' : ''}`} onClick={() => this.onProductIdClcik(prod.productId)} key={prod.productId}>
                            {this.state.selectedProductId === prod.productId && <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />}{prod.title}
                          </Button>
                        )}
                      </div>
                    </div>

                    <div className="pb-3">
                      <div className="product__option-title">
                        Date {pDetail.duration && pDetail.duration !== '' && <span className="font-weight-normal"><IconClock /> {pDetail.duration}</span>}
                      </div>
                      <div className="product__option-date-select">
                        <CustomDatePicker
                          handleSelect={this.onDateChange}
                          value={this.state.selectedDate}
                          hasMinDate
                        />
                      </div>
                    </div>

                    {hasTimeslot &&
                      <div className="pb-3">
                        <div className="product__option-title">
                          Timeslot {this.state.timeSlotError && <AlertText text="Please choose timeslot" />}
                        </div>
                        {this.props.productTimeSlot.map((time, i) =>
                          <Button
                            className={`mr-2 mb-2 ${this.state.selectedTimeslot === time ? 'active' : ''}`}
                            onClick={() => this.onTimeslotClcik(time)}
                            key={i}>
                            {this.state.selectedTimeslot === time && <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />}{time}
                          </Button>
                        )}
                      </div>
                    }

                    {this.props.productSkuPrice &&
                      <div className="pb-3">
                        <div className="product__option-title">
                          Price & Quantity {this.state.quantityError && <AlertText text="Please choose" />}
                        </div>
                          {this.props.productSkuPrice.skuList.length ? this.props.productSkuPrice.skuList.map(sku =>
                            <div className="bg-white px-3 mb-1" key={sku.skuID}>
                              <Row>
                                <Col xs={6} className="p-3 pt-4 h5">
                                  {sku.title}
                                </Col>
                                <Col className={`p-3 ${auth.getUserDetail().isFulfillTeam ? '' : 'pt-4 h5 text-right'}`}>
                                  <Row>
                                    {auth.getUserDetail().isFulfillTeam && <Col className="pr-0">
                                      <div className="small">Net</div>
                                      ${sku.netnetAmount}
                                    </Col>}
                                    {auth.getUserDetail().isFulfillTeam && <Col className="pr-0">
                                      <div className="small">Cost</div>
                                      ${sku.costPrice}
                                    </Col>}
                                    <Col className="pr-0">
                                      {auth.getUserDetail().isFulfillTeam && <div className="small">Sell</div>}
                                      <span className="text-info font-weight-bold">${sku.sellPrice}</span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs="auto" className="p-3">
                                  <ButtonGroup aria-label="Quantity" className="w-100">
                                    <Button variant="secondary" onClick={() => this.onMinusClick(sku.skuID)} disabled={_find(this.state.booksku, {skuId: sku.skuID}) && _find(this.state.booksku, {skuId: sku.skuID}).qty === 0}>-</Button>

                                    <div className="bg-white text-center px-0 form-control form-control-quantity">{_find(this.state.booksku, {skuId: sku.skuID}) && _find(this.state.booksku, {skuId: sku.skuID}).qty}</div>

                                    <Button variant="secondary" onClick={() => this.onPlusClick(sku.skuID)} disabled={_find(this.state.booksku, {skuId: sku.skuID}) && _find(this.state.booksku, {skuId: sku.skuID}).qty === _toNumber(sku.sku_max_pax)}>+</Button>
                                  </ButtonGroup>
                                  {sku.sku_min_pax > 0 && <small className="text-muted">min pax = {sku.sku_min_pax}</small>}
                                </Col>
                              </Row>
                            </div>
                          ):<div className="bg-white p-3 mb-1 text-muted">(No SKU)</div>
                        }
                        <div className="bg-white px-3 mb-1">
                          <Row>
                            <Col xs={9} className="pt-4 h5">
                              Total
                            </Col>
                            <Col xs={3} className="p-3 text-center h3 text-info">
                              ${this.state.totalPrice}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    }

                    {hasExtraInfo && totalQuantity > 0 &&
                      <ul className="layer">
                        <li className="root layer-li">
                          <div className="product__option-title">Extra Information {this.state.extraInfoError && <AlertText text="Please fill in extra information" />}</div>
                        </li>
                        {selectedProduct.extraInfos.general.length > 0 &&
                          <li className="layer-li">
                            <div className="product__option-title">General Info</div>
                            <Table bordered className="mb-0">
                              <tbody>
                                {selectedProduct.extraInfos.general.map(input =>
                                  <tr key={input.infoId}>
                                    <td>{input.infoRemark1} {input.isrequired ? '*' : ''}</td>
                                    <td>{this.getExtraInfoInputType(input, 0)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </li>
                        }
                        {selectedProduct.extraInfos.travelers.length > 0 &&
                          <li className="layer-li">
                            <div className="product__option-title">Traveler Info</div>
                            <Table bordered>
                              <thead>
                                <tr>
                                  <td>#</td>
                                  {selectedProduct.extraInfos.travelers.map(el =>
                                    <td key={el.infoId}>{el.infoRemark1} {el.isrequired ? '*' : ''}</td>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.extraInfo.travelers.map((n, i) =>
                                  <tr key={i}>
                                    <td>{i+1}</td>
                                    {selectedProduct.extraInfos.travelers.map(input =>
                                      <td key={input.infoId}>{this.getExtraInfoInputType(input, i)}</td>
                                    )}
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                            <small>* Required field</small>
                          </li>
                        }
                      </ul>
                    }

                  </div>
                </Col>
                <Col lg="4">
                  <Sticky enabled top={25}>
                    <div className="p-4 right-info-box bg-green product-info rounded">
                      {pDetail.itemList && this.state.selectedProductId &&
                        <div className="mb-3 right-info-box__detail">
                          <div className="product-info__title">Choosed Item Detail</div>

                          <div className="pb-3">
                            <div className="product__option-title"><FontAwesomeIcon icon={faFeather} className="mr-1" />Item</div>
                            <div>{selectedProduct.title}</div>
                          </div>

                          {selectedProduct.detail && selectedProduct.detail !== "" &&
                            <div className="pb-3">
                              <div className="product__option-title"><FontAwesomeIcon icon={faFeather} className="mr-1" />Detail</div>
                              <div>
                                <ReactMarkdown
                                  source={selectedProduct.detail}
                                  escapeHtml={false}
                                />
                              </div>
                            </div>
                          }

                          {selectedProduct.voucherUsage && selectedProduct.voucherUsage !== "" &&
                            <div className="pb-3">
                              <div className="product__option-title"><FontAwesomeIcon icon={faFeather} className="mr-1" />Voucher Usage</div>
                              <div>
                                <ReactMarkdown
                                  source={selectedProduct.voucherUsage}
                                  escapeHtml={false}
                                />
                              </div>
                            </div>
                          }

                          {selectedProduct.voucherTypeDesc && selectedProduct.voucherTypeDesc !== "" &&
                            <div className="pb-3">
                              <div className="product__option-title"><FontAwesomeIcon icon={faFeather} className="mr-1" />Voucher Type Description</div>
                              <div>
                                <ReactMarkdown
                                  source={selectedProduct.voucherTypeDesc}
                                  escapeHtml={false}
                                />
                              </div>
                            </div>
                          }

                          {selectedProduct.confirmationType && selectedProduct.confirmationType !== "" &&
                            <div className="pb-3">
                              <div className="product__option-title"><FontAwesomeIcon icon={faFeather} className="mr-1" />{selectedProduct.confirmationType}</div>
                              {selectedProduct.confirmationDetails !== "" &&
                                <div>
                                  <ReactMarkdown
                                    source={selectedProduct.confirmationDetails}
                                    escapeHtml={false}
                                  />
                                </div>
                              }
                            </div>
                          }

                        </div>
                      }

                      <div className="float-left font-weight-bold pt-2">Total</div>
                      <div className="float-right"><span className="font-weight-bold">{this.props.productSkuPrice && this.props.productSkuPrice.sellCurrency}</span> <span className="h3 text-info">${this.state.totalPrice}</span></div>
                      <div className="float-left small mb-3 text-muted">Your booking cannot be refunded or amended.</div>
                      {auth.getUserDetail().canBook && <Button size="lg" className="btn-block" disabled={this.state.selectedDate === ''} onClick={this.onBookClick}>Review Booking</Button>}
                    </div>
                  </Sticky>
                </Col>
              </Row>
            </div>
          </div>
        }
        {!pDetail && <div className="pt-30vh text-center">No Detail.</div>}
        {pDetail && this.state.totalPrice > 0 && this.props.productSkuPrice.skuList.length > 0 &&
          <ConfirmNavigationModal
            handleCloseTab={() => this.setState({isModalUp: false})}
            handleConfirm={this.creatBooking}
            selectedObect={{
              productName: selectedProduct.title,
              selectedDate: this.state.selectedDate,
              selectedProductId: this.state.selectedProductId,
              selectedTimeslot: this.state.selectedTimeslot,
              booksku: this.state.booksku,
              extraInfo: this.state.extraInfo,
              totalPrice: this.state.totalPrice,
            }}
            show={this.state.isModalUp}
            activityName={this.props.productDetail.title}
            productSkuPrice={this.props.productSkuPrice.skuList}
            extraInfos={selectedProduct.extraInfos}
            currency={this.props.productSkuPrice.sellCurrency}
          />
        }
      </div>
    );
  }
}


ProductDetailPage.propTypes = {
	productDetail: PropTypes.object,
	productTimeSlot: PropTypes.array,
	timeSlotTimeStamp: PropTypes.number,
	productSkuPrice: PropTypes.object,
	bookingID: PropTypes.string,
	createBooking: PropTypes.func,
};

function mapStateToProps(state) {
	return {
    productDetail: state.product.productDetail,
    productTimeSlot: state.product.productTimeSlot,
    timeSlotTimeStamp: state.product.timeSlotTimeStamp,
    productSkuPrice: state.product.productSkuPrice,
    bookingID: state.booking.bookingID,
	}
}

const mapDispatchToProps = {
  getProductDetail,
  getProductTimeSlot,
  getProductSkuPrice,
  clearProductDetail,
  createBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
