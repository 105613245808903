import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Card, Form, Button} from 'react-bootstrap';

import {login} from '../actions/index'; // Import your actions

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.onLoginClick = this.onLoginClick.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);

		this.state = {
			email: '',
			password: '',
		}
	}

	onLoginClick() {
    this.props.login(this.state.email, this.state.password)

	}

	onFieldChange(e) {
		this.setState({
			[e.target.name] : e.target.value
		})
	}

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.onLoginClick()
    }
  }

	render() {
		return (
			<div className="page-login" onKeyDown={(e) => this.onKeyDown(e)}>
				<title>Product Booking System</title>
				<Card style={{ width: '18rem' }}>
					<Card.Body>
						<Card.Title className="text-center">Product Booking System</Card.Title>
						<div className="mb-3">
							<Form.Label className="col-form-label">Email</Form.Label>
							<Form.Control
								type="text"
								name="email"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.email}
							/>
							<Form.Label className="col-form-label">Password</Form.Label>
							<Form.Control
								type="password"
								name="password"
								onChange={(e) => this.onFieldChange(e)}
								value={this.state.password}
							/>
						</div>
						<div className="text-center">
							<Button variant="primary" onClick={() => this.onLoginClick()}>Login</Button>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
}

LoginPage.propTypes = {
  login: PropTypes.func,
  isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    isLoading: state.loading.pageLoading,
	}
}

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
