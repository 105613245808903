import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';

const initialState = {
	hotProducts: null,
	hotLocations: null,
	productDetail: null,
	productTimeSlot: null,
	timeSlotTimeStamp: moment().valueOf(),
	productSkuPrice: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_HOT_PRODUCT_SUCCESS:
	case ActionTypes.GET_HOT_PRODUCT_FAILURE:
		return Object.assign({}, state, {
			hotProducts: action.payload,
		});
	case ActionTypes.GET_HOT_LOCATION_SUCCESS:
	case ActionTypes.GET_HOT_LOCATION_FAILURE:
		return Object.assign({}, state, {
			hotLocations: action.payload,
		});
	case ActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
		return Object.assign({}, state, {
			productDetail: action.payload,
		});
	case ActionTypes.GET_PRODUCT_TIMESLOT_SUCCESS:
	case ActionTypes.GET_PRODUCT_TIMESLOT_FAILURE:
		return Object.assign({}, state, {
			productTimeSlot: action.payload,
			timeSlotTimeStamp: moment().valueOf()
		});
	case ActionTypes.GET_PRODUCT_SKU_SUCCESS:
	case ActionTypes.GET_PRODUCT_SKU_FAILURE:
		return Object.assign({}, state, {
			productSkuPrice: action.payload,
		});
	case ActionTypes.CLEAR_PRODUCT_DETAIL:
		return Object.assign({}, state, {
			productDetail: null,
			productTimeSlot: null,
			productSkuPrice: null,
		});
	default:
		return state;
	}
};

export default product;
