import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cogoToast from 'cogo-toast';

class PopupMsg extends Component {
	componentDidUpdate(prevProps) {
		if (prevProps.timestamp !== this.props.timestamp) {
			if (this.props.isError) {
				cogoToast.error(<div dangerouslySetInnerHTML={{__html: this.props.msg}} />)
			} else {
				cogoToast.success(<div dangerouslySetInnerHTML={{__html: this.props.msg}} />)
			}
		}
	}

	render() {
		return (
			<div/>
	  );
	}
}

PopupMsg.propTypes = {
	msg: PropTypes.string,
	isError: PropTypes.bool,
	timestamp: PropTypes.number,
};

function mapStateToProps(state) {
	return {
    msg: state.msg.msg,
    isError: state.msg.isError,
    timestamp: state.msg.timestamp,
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupMsg);
