import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getHotProducts() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchHotProducts`;
    const body = {
      "token": auth.getAccessToken(),
      "lang": "en-US",
    }

		dispatch({
			type: ActionTypes.GET_HOT_PRODUCT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_HOT_PRODUCT_SUCCESS,
            payload: result.productList
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_HOT_PRODUCT_FAILURE,
  					payload: null,
            msg: `Get hot products failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_HOT_PRODUCT_FAILURE,
					error,
					payload: null,
          msg: `Get hot products failed. API issue.`
				})
			});
	};
}

export function getHotLocations() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetHotLocation`;
    const body = {
      "token": auth.getAccessToken(),
    }

		dispatch({
			type: ActionTypes.GET_HOT_LOCATION_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_HOT_LOCATION_SUCCESS,
            payload: result.locationList
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_HOT_LOCATION_FAILURE,
  					payload: null,
            msg: `Get hot locations failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_HOT_LOCATION_FAILURE,
					error,
					payload: null,
          msg: `Get hot locations failed. API issue.`
				})
			});
	};
}

export function getProductDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchProductWithDetails`;
    const body = {
      "token": auth.getAccessToken(),
      "activityId": id,
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_DETAIL_FAILURE,
  					payload: null,
            msg: `Get product detail failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get product detail failed. API issue.`
				})
			});
	};
}

export function getProductTimeSlot(activityId, productId, date) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchProductTimeSlot`;
    const body = {
      "token": auth.getAccessToken(),
      "lang": "en-US",
      // "activityID": activityId,
      "product_id": productId,
      "requestDate": date,
      "startDate": date,
      "endDate": date
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_TIMESLOT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_TIMESLOT_SUCCESS,
            payload: result.productTimeSlot ? result.productTimeSlot[0].timesSlot : []
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_TIMESLOT_FAILURE,
  					payload: null,
            msg: `Get product timeslot failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_TIMESLOT_FAILURE,
					error,
					payload: null,
          msg: `Get product timeslot failed. API issue.`
				})
			});
	};
}

export function getProductSkuPrice(activityId, productId, date) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchProductSkuPrice`;
    const body = {
      "token": auth.getAccessToken(),
      "lang": "en-US",
      // "activityID": activityId,
      "product_id": productId,
      "requestDate": date,
      "startDate": date,
      "endDate": date
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_SKU_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_SKU_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_SKU_FAILURE,
  					payload: null,
            msg: `Get product sku failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_SKU_FAILURE,
					error,
					payload: null,
          msg: `Get product sku failed. API issue.`
				})
			});
	};
}

export function createBooking(activityId, productId, booksku, date, timeslot, extraInfo, totalPrice, currency) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/NewBooking`;
    let body = {
      "token": auth.getAccessToken(),
      "bookemail": "string",
      "lang": "en-US",
      "activityId": activityId,
      "product_id": productId,
      "booksku": booksku,
      "requestDate": date,
      "timeslot": timeslot,
      "extraInfo": extraInfo, // could be empty,
      "expectedPrice": totalPrice,
      "expectedCurr": currency
    }

    console.log(JSON.stringify(body))

		dispatch({
			type: ActionTypes.CREATE_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_BOOKING_SUCCESS,
            payload: result.productSysID
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_BOOKING_FAILURE,
  					payload: null,
            msg: `Create booking failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_BOOKING_FAILURE,
					error,
					payload: false,
          msg: `Create booking failed. API issue.`
				})
			});
	};
}

export function clearProductDetail() {
  return (dispatch) => {
		dispatch({
			type: ActionTypes.CLEAR_PRODUCT_DETAIL,
		})
	};
}
