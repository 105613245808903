import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Table} from 'react-bootstrap';

import _find from 'lodash/find';
import {prettySingleDate} from '../util/transformDate';

const ConfirmNavigationModal = (props) => {
	return (
		<Modal centered show={props.show} onHide={() => props.handleCloseTab()} >
			<Modal.Header>
				<Modal.Title>Request Booking</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Please confirm the following information.</p>
				<div className="mb-3">
					<div className="product__option-title">Product</div>
					<div>{props.activityName}</div>
				</div>
				<div className="mb-3">
					<div className="product__option-title">Item</div>
					<div>{props.selectedObect.productName}</div>
				</div>
				<div className="mb-3">
					<div className="product__option-title">Travel Date</div>
					<div>{prettySingleDate(props.selectedObect.selectedDate)}</div>
				</div>
				{props.selectedObect.selectedTimeslot !== "00:00" &&
					<div className="mb-3">
						<div className="product__option-title">Timeslot</div>
						<div>{props.selectedObect.selectedTimeslot}</div>
					</div>
				}
				{_find(props.selectedObect.booksku, {skuId: props.productSkuPrice[0].skuID}) &&
					<div className="mb-3">
						<div className="product__option-title">Quantity</div>
						<div>
						{props.selectedObect.booksku && props.productSkuPrice.map(sku =>
							_find(props.selectedObect.booksku, {skuId: sku.skuID}).qty > 0 && <div key={sku.skuID}>{_find(props.selectedObect.booksku, {skuId: sku.skuID}).qty} x {sku.title}</div>
						)}
						</div>
					</div>
				}
				<div className="mb-3">
					<div className="product__option-title">Total Price</div>
					<div className="font-weight-bold text-info text-lg">{props.currency} ${props.selectedObect.totalPrice}</div>
				</div>
				{(props.selectedObect.extraInfo.general || props.selectedObect.extraInfo.travelers) &&
					<div className="mb-3">

						<ul className="layer">
	            <li className="root layer-li product__option-title">Extra Information</li>

							{props.selectedObect.extraInfo.general && props.selectedObect.extraInfo.general.length > 0 &&
								<li className="layer-li">
									<div className="product__option-title">General Info</div>
									<Table bordered className="mb-0">
										<tbody>
											{props.selectedObect.extraInfo.general.map((n, i) =>
												{
													let extraInfoObj = _find(props.extraInfos.general, {infoId: n.typeId})
													let optionObj = extraInfoObj.options && _find(extraInfoObj.options, {key: n.value})
													return (
														<tr key={i}>
															<td width="30%">{extraInfoObj.infoRemark1}</td>
															<td>{n.value && extraInfoObj.infovalue === 'list' ? optionObj.value : n.value}</td>
														</tr>
													)
												}
											)}
										</tbody>
									</Table>
								</li>
							}

							{props.selectedObect.extraInfo.travelers && props.selectedObect.extraInfo.travelers.length > 0 &&
								<li className="layer-li">
									<div className="product__option-title">Traveler Info</div>
									<Table bordered>
										<thead>
											<tr>
												<th>#</th>
												{props.extraInfos.travelers.map((info) =>
													<td key={info.infoId}>{info.infoRemark1}</td>
												)}
											</tr>
										</thead>
										<tbody>
											{props.selectedObect.extraInfo.travelers.map((n, i) =>
												<tr key={i}>
													<td>{i+1}</td>
													{n.info.map(input =>
														{
															let extraInfoObj = _find(props.extraInfos.travelers, {infoId: input.typeId})
															let optionObj = extraInfoObj.options && _find(extraInfoObj.options, {key: input.value})
															return (
																<td key={i}>{input.value && extraInfoObj.infovalue === 'list' ? optionObj.value : input.value}</td>
															)
														}

													)}
												</tr>
											)}
										</tbody>
									</Table>
								</li>
							}
						</ul>

					</div>
				}
			</Modal.Body>
			<Modal.Footer>
			Your booking cannot be refunded or amended.
				<Button variant="primary" onClick={props.handleConfirm} size="lg" className="btn-block">
					Request Now
				</Button>
			</Modal.Footer>
		</Modal>
  );
}

ConfirmNavigationModal.propTypes = {
	handleCloseTab: PropTypes.func,
	handleConfirm: PropTypes.func,
	show: PropTypes.bool,
	selectedObect: PropTypes.object,
	activityName: PropTypes.string,
	productSkuPrice: PropTypes.array,
	extraInfos: PropTypes.object,
	currency: PropTypes.string,
}

export default ConfirmNavigationModal;
