import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

// import locationList from '../mock/locationList.json'

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getAllLocation() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetALlLocation`;
    const body = {
      "token": auth.getAccessToken(),
    }

		dispatch({
			type: ActionTypes.GET_ALL_LOCATION_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_ALL_LOCATION_SUCCESS,
            payload: result.locationList
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_ALL_LOCATION_FAILURE,
  					payload: null,
            msg: `Get location failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_ALL_LOCATION_FAILURE,
					error,
					payload: null,
          msg: `Get location failed. API issue.`
				})
			});
	};
}

export function getProductByCity(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchProducts`;
    const body = {
      "token": auth.getAccessToken(),
      "cityId": id
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_BY_CITY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_BY_CITY_SUCCESS,
            payload: result.productList
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_BY_CITY_FAILURE,
  					payload: null,
            msg: `Get product by city failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_BY_CITY_FAILURE,
					error,
					payload: null,
          msg: `Get product by city failed. API issue.`
				})
			});
	};
}

export function getProductByCountry(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/SearchProductsByCountry`;
    const body = {
      "token": auth.getAccessToken(),
      "countryId": id
    }

		dispatch({
			type: ActionTypes.GET_PRODUCT_BY_COUNTRY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        console.log(result)
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_PRODUCT_BY_COUNTRY_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PRODUCT_BY_COUNTRY_FAILURE,
  					payload: null,
            msg: `Get product by country failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_PRODUCT_BY_COUNTRY_FAILURE,
					error,
					payload: null,
          msg: `Get product by country failed. API issue.`
				})
			});
	};
}
