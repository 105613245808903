import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, Badge, Row, Col, Media} from 'react-bootstrap';
import Auth from '../Auth/Auth';
import {Avatar, IconLogOut, IconArchive, IconMessageSquare} from "sancho";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

import Loading from '../components/Loading';
import ProductSearchBar from '../components/ProductSearchBar';

import {getUnreadBookingMessage} from '../actions/booking';

const auth = new Auth();

class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		if (this.props.isLogged) {
			this.props.getUnreadBookingMessage()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.shouldRefreshBookingMessage && this.props.shouldRefreshBookingMessage) {
			this.props.getUnreadBookingMessage()
		}
	}

	logout() {
		auth.logout();
	}

	render() {
		return (
			<div>
				<div className="top_nav py-2">
			    <Row>
		        <Col lg="auto">
	            <p className="company-font mb-0 mt-1">
								<a className="name" href={`${process.env.PUBLIC_URL}/`}>Product Booking System</a>
							</p>
		        </Col>

						{this.props.isLogged && this.props.currentPage !== '/' &&
							<Col lg="5">
								<ProductSearchBar />
							</Col>
						}
						{this.props.isLogged &&
							<Col lg="auto" className="align-self-end ml-auto">
							<Dropdown alignRight >
								<Dropdown.Toggle variant="none" className="p-0">
									<div className="inline"><Avatar size="sm" name={auth.getName()} /></div>
									{this.props.myUnreadBookingMessage && this.props.myUnreadBookingMessage.length === 0 && <div className="notification-dot"><FontAwesomeIcon icon={faCircle} className="red" size="xs" /></div>}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<a href={`${process.env.PUBLIC_URL}/booking-history`} className="dropdown-item">
										<IconArchive className="mr-2" /> Booking History
									</a>
									<Link
										className="dropdown-item"
										to="/unread-message-list"
									>
										<IconMessageSquare className="mr-2" /> Booking Message <Badge pill variant="danger">{this.props.myUnreadBookingMessage ? this.props.myUnreadBookingMessage.length : '0'}</Badge>
									</Link>
									<Dropdown.Divider />
									<button className="dropdown-item" onClick={this.logout}>

										<Media>
											<IconLogOut className="mr-2 align-self-center" />
										  <Media.Body>
												Logout
												<div className="small text-muted">{auth.getName()}</div>
										  </Media.Body>
										</Media>

									</button>
								</Dropdown.Menu>
							</Dropdown>
							</Col>
						}
			    </Row>
				</div>

				{this.props.isLoading && <Loading />}
			</div>
		);
	}
}


MainMenu.propTypes = {
  isLoading: PropTypes.bool,
  myUnreadBookingMessage: PropTypes.array,
	handleProductClick: PropTypes.func,
	currentPage: PropTypes.string,
	isLogged: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    isLoading: state.loading.pageLoading,
		myUnreadBookingMessage: state.booking.myUnreadBookingMessage,
    shouldRefreshBookingMessage: state.booking.shouldRefreshBookingMessage,
	}
}

const mapDispatchToProps = {
	getUnreadBookingMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
