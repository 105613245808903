import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import {IconMapPin, Link} from "sancho";
import Auth from '../Auth/Auth';
const auth = new Auth();

const ProductCard = (props) => {
	return (
		<Card key={props.prod.activityId} className="product-card">
			{props.prod.images &&
				<div className="product-img-top" style={{backgroundImage: `url(${props.prod.images.length > 0 ? props.prod.images[0].url : 'https://www.iol.co.za/assets/images/general/no-image.png'})`}}>
					<Link href={`/activity/${props.prod.activityId}`} className="btn-block"><span className="sr-only">{props.prod.title}</span></Link>
				</div>
			}
			<Card.Body>
				<Link href={`/activity/${props.prod.activityId}`}>
					<h6>{props.prod.title}</h6>
				</Link>
				<div><IconMapPin /> {props.prod.city}</div>
				{auth.getUserDetail().canViewEn && props.prod.enginecode && <div className="small mt-2">Supplier: {props.prod.enginecode}</div>}
			</Card.Body>
			<Card.Footer className="border-top-0 bg-white">
				<p className="card-text">From <span className="text-danger font-weight-bold">{props.prod.minPriceCurrency} ${props.prod.minPrice}</span> {props.prod.originalamount !== 0 && <span className="text-line-through">{props.prod.originalcurr} ${props.prod.originalamount}</span>}</p>
			</Card.Footer>
		</Card>
  );
}

ProductCard.propTypes = {
	prod: PropTypes.object,
}

export default ProductCard;
