import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {prettySingleDate, slashDateFormat, slashDateFormatToday, slashDateFormat100yearAgo} from '../util/transformDate';

class CustomDatePicker extends Component {
	constructor(props) {
		super(props);
		this.onDateChange = this.onDateChange.bind(this)
		this.onClearDate = this.onClearDate.bind(this)
	}

	onDateChange(event, picker) {
		this.props.handleSelect(
			picker.startDate.format(),
			this.el.name,
			this.props.index,
		)
	}

	onClearDate() {
		this.props.handleSelect(
			null,
			this.el.name,
			''
		)
	}

  render() {
		return (
			<div className="relative">
				<DateRangePicker
					onApply={this.onDateChange}
					autoApply
					singleDatePicker
					startDate={this.props.value ? slashDateFormat(this.props.value) : slashDateFormatToday()}
					drops="up"
					minDate={this.props.hasMinDate ? slashDateFormatToday() : slashDateFormat100yearAgo()}
				>
					<Form.Control
						type="text"
						value={this.props.value ? prettySingleDate(this.props.value) : ''}
						name={this.props.name}
						ref={ref => this.el = ref}
						autoComplete="off"
						onChange={() => console.log()}
						placeholder="Choose a Date"
					/>
				</DateRangePicker>
				{this.props.isClearAvailable && this.props.value && <button type="button" onClick={() => this.onClearDate()} className="btn date-range__cancel-icon"><FontAwesomeIcon icon={faTimes} size="sm" /></button>}
			</div>
    );
  }
}

CustomDatePicker.propTypes = {
	handleSelect: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.string,
	isClearAvailable: PropTypes.bool,
	index: PropTypes.number,
	hasMinDate: PropTypes.bool,
};

export default CustomDatePicker;
