// import {createStore, applyMiddleware} from "redux";
// import thunk from 'redux-thunk';
// // Logger with default options
// import logger from "redux-logger";
//
// import reducers from "./reducers/index";
//
// export default createStore(reducers, applyMiddleware(thunk, logger));
//
// // for chrome redux dev tool
// // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// // export default createStore(
// //   reducers, /* preloadedState, */
// //   composeEnhancers(
// //     applyMiddleware(thunk, logger)
// //   ));


import {createStore, applyMiddleware,compose} from "redux";
import thunk from 'redux-thunk';
// Logger with default options
import logger from "redux-logger";
import reducers from "./reducers/index";
// export default createStore(reducers, applyMiddleware(thunk, logger));
// for chrome redux dev tool
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export default createStore(
  reducers, /* preloadedState, */
  composeEnhancers(
    applyMiddleware(thunk, logger)
  ));
