import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Auth from '../Auth/Auth';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlane} from '@fortawesome/free-solid-svg-icons';
import {IconChevronLeft, IconChevronRight, Link} from "sancho";
import {Row, Col, Card, CardDeck, Carousel} from 'react-bootstrap';
import ProductSearchBar from '../components/ProductSearchBar';
import ProductCard from '../components/ProductCard';

import {getHotProducts, getHotLocations, getProductDetail} from '../actions/product';

import _chunk from 'lodash/chunk';
import _trim from 'lodash/trim';

const auth = new Auth();

class Home extends Component {
	constructor(props) {
		super(props);

    this.onLocationClick = this.onLocationClick.bind(this);

    this.state = {
      currentPage: 0
    }
	}

  componentDidMount() {
		if (!this.props.hotLocations) {
			this.props.getHotLocations()
		}
  }

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.hotLocations && this.props.hotLocations && !this.props.hotProducts) {
			this.props.getHotProducts()
		}
	}


	onLocationClick(id) {
		window.location.href = `/location/${id}`;
	}

  render() {
    return (
      <div>
				<title>Product Booking System</title>
			  <div
					className="search-banner"
			    style={{
			      backgroundImage: `url(/images/home-bg.jpg)`
			    }}
			  >
					<div className="container">
					<h1 className="text-center">Hi {auth.getName()}, Search product here!</h1>
						<Row className="justify-content-center">
							<Col lg="5">
								<ProductSearchBar handleSelect={this.onProductClick} />
							</Col>
						</Row>
					</div>
				</div>


				<div className="container py-5 border-bottom text-center ">
					<h1 className="mb-5">Hot Cities</h1>
					<Row>
						{this.props.hotLocations ? this.props.hotLocations.map((lct, i) =>
							<Col lg="3" className="mb-4" key={i}>
								<Card>
									<div className="product-img-top" style={{backgroundImage: `url(${lct.thumbnailUrl ? lct.thumbnailUrl : "https://img.icons8.com/clouds/100/000000/worldwide-location.png"})`}}/>
									<Card.ImgOverlay className="card--hot-location">
										<Link href={`/location/${_trim(lct.countryId)}/${lct.cityid}`} className="btn-block">
											<Card.Title className="white">{lct.city}</Card.Title>
										</Link>
									</Card.ImgOverlay>
								</Card>
							</Col>
						) : <Col className="text-center">Loading...</Col>}
					</Row>
					<a href="/location" className="btn btn-lg btn-outline-primary mt-4"><FontAwesomeIcon icon={faPlane} className="mr-2" />All Locations</a>
				</div>



				<div className="container py-5">
					<h1 className="text-center mb-5">Hot Products</h1>

					{this.props.hotProducts ?
						<Carousel
							className="hot-product-carousel"
							indicators={false}
							interval={null}
							prevIcon={<IconChevronLeft />}
							nextIcon={<IconChevronRight />}
							controls={this.props.hotProducts.length > 4}>
							{_chunk(this.props.hotProducts, 4).map((chunkProd, i) =>
								<Carousel.Item key={i}>
									<CardDeck>
										{chunkProd.map((prod) =>
											<ProductCard prod={prod} key={prod.activityId} />
										)}
									</CardDeck>
								</Carousel.Item>
							)}
						</Carousel>
					: <div className="hot-product-carousel-laoding-placeholder">Loading...</div>}
				</div>

      </div>
    );
  }
}

Home.propTypes = {
	hotProducts: PropTypes.array,
	hotLocations: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    hotProducts: state.product.hotProducts,
    hotLocations: state.product.hotLocations,
    hotProductLoading: state.product.hotProductLoading,
    hotLocationLoading: state.product.hotLocationLoading,
	}
}

const mapDispatchToProps = {
	getHotProducts,
	getHotLocations,
	getProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
