import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {CardDeck, Row, Col, Form, Carousel, Card} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link, IconChevronRight, IconChevronLeft} from "sancho";
import ProductCard from '../components/ProductCard';
import ProductTypeSelect from '../components/ProductTypeSelect';

import _chunk from 'lodash/chunk';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';

import {getProductByCountry} from '../actions/location';

class CountryPage extends Component {
  constructor(props) {
    super(props);

    this.onOrderBySelect = this.onOrderBySelect.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);

    this.state = {
      orderBy: 'activityId',
      filterdProductTypeCode: ''
    };
  }

  componentDidMount() {
    this.props.getProductByCountry(this.props.match.params.country)
  }

  componentWillUnmount() {
    // this.props.clearProductDetail()
  }

  componentDidUpdate(prevProps, prevState) {

  }

  onSelectClick(name, value, index) {
    this.setState({
      [name]: value
    })
  }

  onOrderBySelect(e) {
    this.setState({
      orderBy: e.target.value
    })
  }

  render () {

    var hasCountryInfo = this.props.countryInfo
    var hasProduct = hasCountryInfo && this.props.countryInfo.productInfoList.length > 0
    var productList = []
    if (hasCountryInfo) {
      productList = this.state.filterdProductTypeCode ? _filter(this.props.countryInfo.productInfoList, ['producttypecode', this.state.filterdProductTypeCode]) : this.props.countryInfo.productInfoList
    }

    return (
      <div>
        <title>{hasCountryInfo && `${this.props.countryInfo.location.country} |`} Product Booking System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link href="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem><Link href="/location">All locations</Link></BreadcrumbItem>
            <BreadcrumbItem>{hasCountryInfo && this.props.countryInfo.location.country}</BreadcrumbItem>
          </Breadcrumbs>
        </div>

        {hasCountryInfo &&
          <div className="country-jumbotron">
            <div className="container">
              <Row>
                <Col lg="5" className={this.props.countryInfo.location.cityList.length ? 'mt-5 pt-3' : ''}>{this.props.countryInfo.location.country}</Col>
                {this.props.countryInfo.location.cityList.length &&
                  <Col>
                    <Carousel
                      indicators={false}
                      interval={null}
                      prevIcon={<IconChevronLeft />}
                      nextIcon={<IconChevronRight />}
                      controls={this.props.countryInfo.location.cityList.length > 3}
                      className="country__carousel">
                      {_chunk(this.props.countryInfo.location.cityList, 3).map((chunkCity, i) =>
                        <Carousel.Item key={i}>
                          <CardDeck>
                            {chunkCity.map((city) =>
                              <Card key={city.cityid} className="card">
                                <div className="product-img-top" style={{backgroundImage: `url(${city.imageUrl ? city.imageUrl : "https://img.icons8.com/clouds/100/000000/worldwide-location.png"})`}}/>
                                <Card.ImgOverlay className="card--hot-location">
              										<Link href={`/location/${this.props.countryInfo.location.countryId}/${city.cityid}`} className="btn-block">
              											<Card.Title className="white">{city.city}</Card.Title>
              										</Link>
              									</Card.ImgOverlay>
                              </Card>
                            )}
                          </CardDeck>
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </Col>
                }
              </Row>
            </div>
          </div>
        }

        <div className="container">
          {hasProduct ?
            <div>
              <Row>
                <Col lg="3" className="mr-auto">
                  <ProductTypeSelect
                    name="filterdProductTypeCode"
                    selectedCode={this.state.filterdProductTypeCode}
                    handleSelect={this.onSelectClick}
                    isClearable
                  />
                </Col>
                <Col lg="3" className="ml-auto text-right pt-2 pr-0">
                  Order By
                </Col>
                <Col lg="3">
                  <Form.Control
                    as="select"
                    name="orderBy"
                    onChange={(e) => this.onOrderBySelect(e)}
                    className="mb-3"
                    >
                      <option value="activityId">Recently added</option>
                      <option value="minPrice">Price (low to hight)</option>
                  </Form.Control>
                </Col>
              </Row>

              <p className="small">Total products: {productList.length}</p>

              {
                productList.length > 0 ?
                  _chunk(_orderBy(productList, [this.state.orderBy, 'asc']), 4).map((row, i) =>
                  <CardDeck className="mb-4" key={i}>
                    {row.map(prod =>
      								<ProductCard prod={prod} key={prod.activityId} />
                    )}
                  </CardDeck>
                ):
                <div className="p-5 text-center card">No Products.</div>
              }
            </div>:
            <div className="p-5 text-center card">No Products.</div>
          }
        </div>
      </div>
    );
  }
}


CountryPage.propTypes = {
	countryInfo: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    countryInfo: state.location.countryInfo,
	}
}

const mapDispatchToProps = {
  getProductByCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryPage);
