import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function login(loginemail, password) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/GetToken`;
    const body = {
      "loginemail": loginemail,
      "keywords": password
    }

		dispatch({
			type: ActionTypes.LOGIN_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          auth.getDetails(result.token);
        } else {
          dispatch({
  					type: ActionTypes.LOGIN_FAILURE,
  					payload: null,
            msg: result.responseMessage,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.LOGIN_FAILURE,
					error,
					payload: null,
          msg: `Login failed. API issue.`
				})
			});
	};
}

export function logout() {
  return (dispatch) => {
    auth.logout();

    // dispatch({
    //   type: ActionTypes.LOG_OUT,
    //   payload: null
    // })
  }
}
