export const currency =
  [
    "AED",
    "ATS",
    "AUD",
    "AUS",
    "BEF",
    "BFR",
    "BND",
    "CAD",
    "CHF",
    "CNY",
    "DEM",
    "DKK",
    "DRA",
    "ESP",
    "EUR",
    "FID",
    "FIM",
    "FJD",
    "FMK",
    "FRF",
    "GBP",
    "GRD",
    "HKD",
    "INR",
    "ITL",
    "JPY",
    "KRW",
    "MOP",
    "MUR",
    "MYR",
    "NLG",
    "NOK",
    "NZD",
    "PHP",
    "PTE",
    "PTS",
    "RMB",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TWD",
    "UAE",
    "US$",
    "US1",
    "USD",
    "ZAR"
  ]

export const month=[
  {name: "Jan", value: 1},
  {name: "Feb", value: 2},
  {name: "Mar", value: 3},
  {name: "Apr", value: 4},
  {name: "May", value: 5},
  {name: "Jun", value: 6},
  {name: "Jul", value: 7},
  {name: "Aug", value: 8},
  {name: "Sep", value: 9},
  {name: "Oct", value: 10},
  {name: "Nov", value: 11},
  {name: "Dec", value: 12},
]

export const status = [
  {name: 'Confirmed', variant: 'success', code: 'KK'},
  {name: 'Cancelled', variant: 'danger', code: 'UC'},
  {name: 'Un-confirmed', variant: 'warning', code: 'XX'},
  {name: 'Refund', variant: 'warning', code: 'RE'},
  {name: 'Request Send', variant: 'warning', code: 'RQ'},
]
