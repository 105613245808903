import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col, Card, CardDeck, Carousel} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link, IconChevronLeft, IconChevronRight} from "sancho";

import _chunk from 'lodash/chunk';

import {getAllLocation} from '../actions/location';

class AllLocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.props.getAllLocation(this.props.match.params.id)
  }

  onLocationClick(id) {
    window.location.href = `/location/${id}`;
  }

  render () {
    return (
      <div>
        <title>All Locations | Product Booking System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link href="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>All locations</BreadcrumbItem>
          </Breadcrumbs>


          {this.props.locationList && this.props.locationList.map((country, i) =>
            <Row className="mb-4" key={country.countryId}>
              <Col lg="3">
                <div className="h1 country-title"><Link href={`/location/${country.countryId}`} className="btn-block"><span>{country.country}</span></Link></div>
              </Col>
              <Col>
                <Carousel
                  className="hot-product-carousel"
                  indicators={false}
                  interval={null}
                  prevIcon={<IconChevronLeft />}
                  nextIcon={<IconChevronRight />}
                  controls={country.cityList.length > 4 ? true : false}
                >
    							{_chunk(country.cityList, 4).map((city, i) =>
    								<Carousel.Item key={i}>
    									<CardDeck>
    										{city.map((ct) =>
                          <Card key={ct.cityid} className="product-card">
                            <div className="product-img-top" style={{backgroundImage: `url(${ct.thumbnailUrl ? ct.thumbnailUrl : "https://img.icons8.com/clouds/100/000000/worldwide-location.png"})`}} />
            								<Card.ImgOverlay className="card--hot-location">
            									<Link href={`/location/${country.countryId}/${ct.cityid}`} className="btn-block">
            										<Card.Title className="white">{ct.city}</Card.Title>
            									</Link>
            								</Card.ImgOverlay>
            							</Card>
    										)}
    									</CardDeck>
    								</Carousel.Item>
    							)}
    						</Carousel>
              </Col>
						</Row>
          )}
        </div>
      </div>
    );
  }
}


AllLocationPage.propTypes = {
	locationList: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    locationList: state.location.locationList,
	}
}

const mapDispatchToProps = {
  getAllLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllLocationPage);
