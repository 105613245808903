import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {CardDeck, Row, Col, Form} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link} from "sancho";
import ProductCard from '../components/ProductCard';
import ProductTypeSelect from '../components/ProductTypeSelect';

import _chunk from 'lodash/chunk';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';

import {getProductByCity} from '../actions/location';

class CityPage extends Component {
  constructor(props) {
    super(props);

    this.onOrderBySelect = this.onOrderBySelect.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);

    this.state = {
      orderBy: 'activityId',
      filterdProductTypeCode: ''
    };
  }

  componentDidMount() {
    console.log(this.props.match)
    this.props.getProductByCity(this.props.match.params.city)
  }

  componentWillUnmount() {
    // this.props.clearProductDetail()
  }

  componentDidUpdate(prevProps, prevState) {

  }

  onSelectClick(name, value, index) {
    this.setState({
      [name]: value
    })
  }

  onOrderBySelect(e) {
    this.setState({
      orderBy: e.target.value
    })
  }

  onLocationClick(id) {
    window.location.href = `/location/${id}`;
  }

  render () {
    var hasProduct = this.props.productList && this.props.productList.length > 0
    var productList = this.state.filterdProductTypeCode ? _filter(this.props.productList, ['producttypecode', this.state.filterdProductTypeCode]) : this.props.productList

    return (
      <div>
        <title>{hasProduct && `${this.props.productList[0].city} |`} Product Booking System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link href="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem><Link href="/location">All locations</Link></BreadcrumbItem>
            <BreadcrumbItem><Link href={`/location/${this.props.match.params.country}`}>{hasProduct && this.props.productList[0].country}</Link></BreadcrumbItem>
            <BreadcrumbItem>{hasProduct && this.props.productList[0].city}</BreadcrumbItem>
          </Breadcrumbs>

          {hasProduct &&
            <Row>
              <Col lg="3" className="mr-auto">
                <ProductTypeSelect
  								name="filterdProductTypeCode"
  								selectedCode={this.state.filterdProductTypeCode}
  								handleSelect={this.onSelectClick}
  								isClearable
  							/>
              </Col>
              <Col lg="3" className="ml-auto text-right pt-2 pr-0">
                Order By
              </Col>
              <Col lg="3">
                <Form.Control
                  as="select"
                  name="orderBy"
                  onChange={(e) => this.onOrderBySelect(e)}
                  className="mb-3"
                  >
                    <option value="activityId">Recently added</option>
                    <option value="minPrice">Price (low to hight)</option>
                </Form.Control>
              </Col>
  					</Row>
          }

          {hasProduct &&
            <p className="small">Total products: {productList.length}</p>
          }

          {hasProduct && productList.length > 0 ?
              _chunk(_orderBy(productList, [this.state.orderBy, 'asc']), 4).map((row, i) =>
              <CardDeck className="mb-4" key={i}>
                {row.map(prod =>
  								<ProductCard prod={prod} key={prod.activityId} />
                )}
              </CardDeck>
            ):
            <div className="p-5 text-center card">No Products.</div>
          }
        </div>
      </div>
    );
  }
}


CityPage.propTypes = {
	productList: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    productList: state.location.productList,
	}
}

const mapDispatchToProps = {
  getProductByCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(CityPage);
