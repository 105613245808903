import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _filter from 'lodash/filter';
import Auth from '../Auth/Auth';
const auth = new Auth();

const initialState = {
	bookingID: null,
	bookingDetail: null,
	bookingHistory: null,
	resendVoucherTimestamp: moment().valueOf(),
	shouldRefreshBooking: false,
	historyMsg: null,
	shouldGetHistory: false,
	bookingMessage: null,
	shouldRefreshBookingMessage: false,
	allUnreadBookingMessage: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.CREATE_BOOKING_SUCCESS:
	case ActionTypes.CREATE_BOOKING_FAILURE:
		return Object.assign({}, state, {
			bookingID: action.payload,
		});
	case ActionTypes.GET_BOOKING_DETAIL_SUCCESS:
	case ActionTypes.GET_BOOKING_DETAIL_FAILURE:
		return Object.assign({}, state, {
			bookingDetail: action.payload,
			shouldRefreshBooking: false,
		});
	case ActionTypes.GET_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.GET_BOOKING_HISTORY_FAILURE:
		return Object.assign({}, state, {
			bookingHistory: action.payload,
		});
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_FAILURE:
		return Object.assign({}, state, {
			historyMsg: action.payload,
		});
	case ActionTypes.ADD_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.ADD_BOOKING_HISTORY_FAILURE:
		return Object.assign({}, state, {
			shouldGetHistory: action.payload,
		});
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshBooking: true,
		});
	case ActionTypes.CANCEL_BOOKING_FAILURE:
		return Object.assign({}, state, {
			shouldGetHistory: false,
		});
	case ActionTypes.RESEND_VOUCHER_SUCCESS:
		return Object.assign({}, state, {
			resendVoucherTimestamp: moment().valueOf(),
			shouldGetHistory: true,
		});
	case ActionTypes.RESEND_VOUCHER_FAILURE:
		return Object.assign({}, state, {
			shouldGetHistory: false,
		});
	case ActionTypes.GET_BOOKING_MESSAGE_SUCCESS:
		return Object.assign({}, state, {
			bookingMessage: action.payload,
			shouldRefreshBookingMessage: false,
		});
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
	case ActionTypes.READ_BOOKING_MESSAGE_SUCCESS:
	return Object.assign({}, state, {
			shouldRefreshBookingMessage: true,
		});
	case ActionTypes.GET_UNREAD_BOOKING_MESSAGE_SUCCESS:
	const myMsg = _filter(action.payload, r => (r.TypeCode === auth.getCompanyCode() && r.Login === auth.getUserCode()))
	return Object.assign({}, state, {
			allUnreadBookingMessage: action.payload,
			myUnreadBookingMessage: action.payload.filter( ( el ) => !myMsg.includes( el ) ),
		});
	default:
		return state;
	}
};

export default product;
