import React, {Component} from 'react';
import Auth from '../Auth/Auth';
import {ComboBox, ComboBoxInput, ComboBoxList, ComboBoxOption, ComboBoxOptionText, ListItem, IconMapPin, ListSection} from "sancho";

import _debounce from 'lodash/debounce';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();
const endpoint = `${currentServerURL}/SearchProductsBykeywords`;
const method = 'POST'
const header = {
	'Content-Type': 'application/json'
}


class ProductSearchBar extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.delayedCallback = _debounce(this.onSelectInputChange, 500).bind(this);
		this.onQueryChange = this.onQueryChange.bind(this);

		this.state = {
			options: null,
			isSearching: false,
			currentObj: '',
			value: '',
		};
	}

	componentDidMount() {
	}

	onQueryChange(value) {
		this.setState({
			value: value,
			options: null
		})

		if (value) {
			this.delayedCallback(value)
		} else {
			this.clearLoginOptions()
		}
	}

	onSelectInputChange(string) {
		if (string) {
			this.setState({
				isSearching: true
			})
	    fetch(endpoint, {
				method: method,
				headers: header,
	      body: JSON.stringify({
					"token": auth.getAccessToken(),
				  "lang": "en-US",
				  "keywords": string
				}),
			})
				.then(response => response.json())
				.then((result) => {
					if (result.responseSuccess) {
						console.log(result)
						this.setState({
							options: {productList: result.productList, locationList: result.locationList},
							isSearching: false,
						})
					} else {
						this.setState({
							isSearching: false
						})
					}

				})
				.catch((error) => {
					console.log('error: ', error)
					this.setState({
						isSearching: false
					})
				});

		} else {
			this.clearLoginOptions()
		}
	}

	onSelectClick(selectedOption) {
		console.log(selectedOption)
		if (selectedOption) {
			if (selectedOption.activityId) {
				this.setState({
					currentObj: selectedOption,
					value: selectedOption.title
				})
				window.location.href = `/activity/${selectedOption.activityId}`;
			} else {
				this.setState({
					currentObj: selectedOption,
					value: selectedOption.city
				})
				window.location.href = `/location/${selectedOption.countryId}/${selectedOption.cityid}`;
			}
		}
	}

	clearLoginOptions() {
		this.setState({
			options: null,
			currentObj: '',
		});
	}

  render() {
		return (
			<div className="product-search-bar-wrapper">
				<div className="px-0">
					<ComboBox
						query={this.state.value}
			      onQueryChange={v => this.onQueryChange(v)}
			      onSelect={v => this.onSelectClick(v)}
					>
						<ComboBoxInput
							className="form-control"
							aria-label="Query product"
							placeholder="Search Product or Location"
						/>

						{this.state.value && this.state.options && (
							<ComboBoxList aria-label="Query product">
							  {this.state.options.productList.length > 0 &&
									<ListSection title="Product" className="section-title">
										{this.state.options.productList.map((entry, i) => (
									    <ComboBoxOption value={entry} key={i}>
									      <ListItem
									        interactive={false}
									        primary={<ComboBoxOptionText value={entry.title} />}
									        contentAfter={<div><IconMapPin className="small-icon" /> {entry.city}</div>}
									      />
									    </ComboBoxOption>
										))}
									</ListSection>
							  }
							  {this.state.options.locationList.length > 0 &&
									<ListSection title="City" className="section-title">
										{this.state.options.locationList.map((entry, i) => (
									    <ComboBoxOption value={entry} key={i}>
									      <ListItem
									        interactive={false}
									        primary={<div><IconMapPin /> {entry.city}</div>}
									      />
									    </ComboBoxOption>
								  	))}
									</ListSection>
								}
							</ComboBoxList>
						)}
					</ComboBox>
					{this.state.isSearching &&
						<div className="spinner-border spinner-border-sm text-secondary product-search-bar__loading" role="status">
						  <span className="sr-only">Loading...</span>
						</div>
					}
				</div>
			</div>
    );
  }
}


export default ProductSearchBar;
