import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckDouble, faCheckCircle, faCheck, faReply, faCircle} from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-autosize-textarea';

import _extend from 'lodash/extend';
import _get from 'lodash/get';

import {prettyDateTime} from '../../util/transformDate.js';

import Auth from '../../Auth/Auth';
const auth = new Auth();

class BookingMessageList extends Component {
	constructor(props) {
		super(props);
		this.onFormChange = this.onFormChange.bind(this)
		this.onReadClick = this.onReadClick.bind(this)
		this.onReplyClick = this.onReplyClick.bind(this)
		this.onPageChange = this.onPageChange.bind(this)
		this.onRestClick = this.onRestClick.bind(this)
		this.onSendClick = this.onSendClick.bind(this)

		this.state = {
			form: {
				subject: '',
				detail: ''
			},
			currentPage: 0
		};

	}

	onFormChange(e) {
		let key = e.target.name
		let value = e.target.value

		this.setState({
			form: _extend(this.state.form, {
				[key]: (value)
			})
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onSendClick()
		}
	}

	onReadClick(autonum) {
		this.props.handleReadMsg(autonum)
	}

	onRestClick() {
		this.setState({
			form: {
				subject: '',
				detail: ''
			}
		})
	}

	onReplyClick(subject) {
		this.setState({
			form: _extend(this.state.form, {
				subject: `RE: ${subject}`
			})
		})
		window.scrollTo(0,document.body.scrollHeight);
	}

	onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

	onSendClick() {
		this.props.handleCreateMsg(
			this.props.orderId,
			this.state.form
		);
	}


	render() {
		const hasMessage = this.props.htlMessages && this.props.htlMessages.length > 0
		return (
			<div className="mt-4">
				<div className="float-right"><FontAwesomeIcon icon={faCircle} className="red" size="xs" /> = Unread</div>
				{this.props.htlMessages && <p>{this.props.htlMessages.length} record(s)</p>}

				{hasMessage ?
					<div className="message-body">
						{this.props.htlMessages.map((msg, i) =>
							{const isMe = auth.getCompanyCode() === _get(msg, 'TypeCode') && auth.getUserCode() === _get(msg, 'Login')
								return (
									<div className="msg" key={i}>
										<div className={`bubble ${isMe ? 'alt' : ''}`}>
											<div className="txt">
												<div className={`name ${isMe ? 'alt' : ''}`}>
												{!_get(msg, 'Readdate') && !isMe && <FontAwesomeIcon icon={faCircle} className="red mr-1" size="xs" title="Unread" />}
												{_get(msg, 'TypeCode')} {_get(msg, 'Login')}</div>
												<div className="message">
													<div><u>{_get(msg, 'Subject')}</u></div>
													<div className="pre-line">{_get(msg, 'Detail')}</div>
												</div>
												<div className="timestamp">
													<span title="Send date">{prettyDateTime(_get(msg, 'Senddate'))}</span>
													{isMe ?
														(_get(msg, 'Readdate') ?
															<div className="inline pl-2" title="Read" ><FontAwesomeIcon icon={faCheckDouble} className="read-blue"/></div>:
															<FontAwesomeIcon icon={faCheck} className="rounded-circle grey ml-2" title="Sent but not read" />
															) :
														(_get(msg, 'Readdate') ?
															<div className="inline pl-2" title="Read" ><FontAwesomeIcon icon={faCheckDouble} className="read-blue"/></div>:
															<Button className="pr-0 pl-2" variant="icon" onClick={() => this.onReadClick(_get(msg, 'Autonum'))} title="Mark as read">
																<FontAwesomeIcon icon={faCheckCircle} className="rounded-circle" />
															</Button>)
													}
													<Button className={`rounded-circle reply-button ${isMe ? 'alt' : ''}`} variant="icon" onClick={() => this.onReplyClick(_get(msg, 'Subject'))} title="Reply">
														<FontAwesomeIcon icon={faReply} />
													</Button>
												</div>
											</div>

											<div className={`bubble-arrow ${isMe ? 'alt' : ''}`} />
										</div>
									</div>
								)
							}
						)}
					</div> :
					<div className="p-5 text-center card">No Messages.</div>
				}

				{
					<div className="pb-4 mt-5 bg-grey">
						<div className="row justify-content-center">
							<div className="col-lg-8 pt-4">
								<div>
									<div className="form-group row">
										<div className="col-3 text-right">
										</div>
										<div className="col-9">
											<h3>Send Message</h3>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-3 text-right">
											Send To
										</div>
										<div className="col-9">
											FULFILTEAM
										</div>
									</div>
									<div className="form-group row">
										<div className="col-3 text-right">
											<Form.Label htmlFor="subject">Subject</Form.Label>
										</div>
										<div className="col-9">
											<Form.Control
												type="text"
												value={this.state.form.subject}
												id="subject"
												name="subject"
												onChange={(e) => this.onFormChange(e)}
												onKeyDown={(e) => this.onKeyDown(e)}
												maxLength={50}
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-3 text-right">
											<Form.Label htmlFor="detail">Detail</Form.Label>
										</div>
										<div className="col-9">
											<TextareaAutosize
												value={this.state.form.detail}
												name="detail"
												rows={10}
												className="form-control"
												onResize={(e) => {}}
												onChange={(e) => this.onFormChange(e)}
												maxLength={2000}
											/>
											<div className="text-center mt-4">
												<Button
													className="mr-4"
													variant="secondary"
													onClick={() => this.onSendClick()}
													disabled={!this.state.form.subject && !this.state.form.detail}
												>Submit</Button>
												<Button variant="outline-secondary" type="button" onClick={() => this.onRestClick()} >Reset</Button>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>
					</div>
				}
			</div>

		);
	}
}

BookingMessageList.propTypes = {
	bookingDetail: PropTypes.object,
	htlMessages: PropTypes.array,
	handleReadMsg: PropTypes.func,
	handleCreateMsg: PropTypes.func,
	orderId: PropTypes.string
};

export default BookingMessageList;
