import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import Auth from '../Auth/Auth';
import history from '../history';
import {Spinner} from "sancho";
import {Card} from 'react-bootstrap';

import '../style/style.scss';

import LoginPage from './LoginPage';
import MainMenu from './MainMenu';
import Home from './Home';
import ProductDetailPage from './ProductDetailPage';
import BookingDetailPage from './BookingDetailPage';
import BookingHistoryPage from './BookingHistoryPage';
import AllLocationPage from './AllLocationPage';
import CityPage from './CityPage';
import CountryPage from './CountryPage';
import PopupMsg from '../components/PopupMsg';
import UnreadBookingMsgPage from './UnreadBookingMsgPage';

import store from '../store'; // Import the store
import _replace from 'lodash/replace';

const auth = new Auth();

class App extends Component {
  constructor(props) {
    super(props);
    this.goToProduct = this.goToProduct.bind(this);

    this.state = {
      isLogged: false,
      isLoading: false
		};
  }

  componentWillMount() {
    if (!auth.hasSession()) {
      if (history.location.pathname.includes('/login')) {
        if (history.location.pathname.includes('/login/')) { //token page
          const token = _replace(history.location.pathname, '/login/', '')

          if (token) {
            auth.getDetails(token)
          }
        }
      } else {
        history.push(`/login`)
      }
    } else {
      if (auth.isExpired()) {
        auth.renewSession()
      } else {
        if (history.location.pathname.includes('/login/')) {
          window.location.href= '/'
        }
      }

    }
  }

  componentDidMount() {

  }

  setLogged() {
    this.setState({
      isLogged: true,
      isLoading: false
    })
  }

  setLoading(bool) {
    this.setState({
      isLoading: bool
    })
  }

  goToProduct(id) {
    history.push(`/activity/${id}`)
  }

  render() {
    const NoMatch = () => (
      <div className="text-center">
        NoMatch
      </div>
    )
    const GetToken = () => (
      <div className="page-login">
				<Card style={{ width: '18rem' }}>
					<Card.Body>
						<Card.Title className="text-center">Product Booking System</Card.Title>
            <div style={{ height: "150px" }}>
              <Spinner label="Redirecting..." center size="xl" />
            </div>
					</Card.Body>
				</Card>
			</div>
    )
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="app body-container">
            <div>
              <MainMenu
                handleProductClick={this.goToProduct}
                currentPage={history.location.pathname}
                isLogged={auth.hasSession()}
              />
              <main>
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={(props) => <Home {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/activity/:id`}
                    render={(props) => <ProductDetailPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/booking/:id`}
                    render={(props) => <BookingDetailPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/booking-history`}
                    render={(props) => <BookingHistoryPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/location`}
                    render={(props) => <AllLocationPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/location/:country`}
                    render={(props) => <CountryPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/location/:country/:city`}
                    render={(props) => <CityPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/login`}
                    render={(props) => <LoginPage {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/login/:token`}
                    component={GetToken}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/unread-message-list`}
                    render={(props) => <UnreadBookingMsgPage {...props} />}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </main>
            </div>

          </div>
          <PopupMsg />
        </Router>
      </Provider>
    );
  }
}

export default App;
