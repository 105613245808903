import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col, Badge, ListGroup, Form, Button, Card} from 'react-bootstrap';
import {Breadcrumbs, BreadcrumbItem, Link, Text} from "sancho";
import Pagination from '../components/Pagination';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import _find from 'lodash/find';

import {prettySingleDate, prettyDateTime} from '../util/transformDate';
import {status} from '../util/constances';
import {getBookingList} from '../actions/booking';

class BookingHistoryPage extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this);

    this.state = {
      searchStatus: '',
      searchActivityId: '',
      searchProductId: '',
      searchProductName: '',
      searchProductSySID: '',
      searchPage: 0,
    };
  }

  componentDidMount() {
    this.props.getBookingList(
      this.state.searchStatus,
      this.state.searchActivityId,
      this.state.searchProductId,
      this.state.searchProductName,
      this.state.searchProductSySID,
      0,
    )
  }

  onPageChange(page) {
    this.setState({
      searchPage: page
    })
    this.props.getBookingList(
      this.state.searchStatus,
      this.state.searchActivityId,
      this.state.searchProductId,
      this.state.searchProductName,
      this.state.searchProductSySID,
      page
    )
  }

  onSearchClick() {
    this.onPageChange(0)
  }

  onSearchInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
			this.onSearchClick()
    }
  };

  render () {
    return (
      <div>
        <title>Booking History | Product Booking System</title>
        <div className="container">
          <Breadcrumbs className="mt-1 mb-3">
            <BreadcrumbItem>
              <Link href="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Booking History</BreadcrumbItem>
          </Breadcrumbs>
         </div>

        <div className="bg-grey p-4 mb-4" onKeyDown={this.onKeyDown}>
          <Card>
						<Card.Body>
              <Row>
                <Col>
                  Order ID
                  <Form.Control
                    type="text"
                    placeholder="Search Order ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductSySID"
                    value={this.state.searchProductSySID} />
                </Col>
                <Col>
                  Search Product
                  <Form.Control
                    type="text"
                    placeholder="Search Product"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductName"
                    value={this.state.searchProductName} />
                </Col>
                <Col>
                  Activity ID
                  <Form.Control
                    type="text"
                    placeholder="Search Activity ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchActivityId"
                    value={this.state.searchActivityId} />
                </Col>
                <Col>
                  Product ID
                  <Form.Control
                    type="text"
                    placeholder="Search Product ID"
                    onChange={(e) => this.onSearchInputChange(e)}
                    name="searchProductId"
                    value={this.state.searchProductId} />
                </Col>
                <Col>
                  Search Status
                  <Form.Control
                    as="select"
      							name="searchStatus"
                    onChange={(e) => this.onSearchInputChange(e)}
                    className="mb-3"
                    >
                      <option value="">ALL Status</option>
                      <option value="KK">Confirmed</option>
                      <option value="UC">Cancelled</option>
                      <option value="XX">Un-confirmed</option>
                      <option value="RE">Refund</option>
                      <option value="RQ">Request Send</option>
                  </Form.Control>
                </Col>
                <Col>
                  <Button onClick={() => this.onSearchClick()} className="btn-block mt-4"><FontAwesomeIcon icon={faSearch} className="mr-1" />Search</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        <div className="container">
          {this.props.bookingHistory ?
            <div>
              <div className="small p-2">Total histoy: {this.props.bookingHistory.totalBkg}</div>

              {this.props.bookingHistory.totalBkg > 0 ?
                <ListGroup variant="flush">
                  {this.props.bookingHistory.bkgList.map(record =>
                    <ListGroup.Item action href={`booking/${record.productSySID}`} key={record.productSySID}>
                      <div className="px-3 py-2 bg-green small rounded text-right">
                        <div className="float-left">Order ID : {record.productSySID}</div>
                        {prettyDateTime(record.createOn)}
                      </div>
                      <div className="px-3 py-1">
                        <Row className="mt-2">
                          <Col lg="7">
                            <Text variant="lead">{record.activityName}</Text>
                            <Text variant="h6" className="mb-0">{record.productName}</Text>

                            {_find(status, sta => sta.code === record.bookingStatus) ? <Badge variant={_find(status, sta => sta.code === record.bookingStatus).variant}>{_find(status, sta => sta.code === record.bookingStatus).name}</Badge> : record.bookingStatus}
                          </Col>
                          <Col lg="2">
                            <div><small>Travel Date</small></div>
                            {prettySingleDate(record.requestDate)}
                          </Col>
                          <Col lg="3" className="text-right">
                          <span className="align-top">{record.sellCurrency}</span> <span className="h2 text-info">${record.sellTtlAmt}</span></Col>
                        </Row>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup> :
                <div className="text-center no-result">No results.</div>
              }

              {this.props.bookingHistory.totalPage > 1 &&
                <Pagination
                  noOfPages={this.props.bookingHistory.totalPage}
                  currentPage={this.state.searchPage}
                  handlePageChange={this.onPageChange}
                />
              }
            </div>:
            <div className="p-5 text-center card">No Histories.</div>
          }
        </div>
      </div>
    );
  }
}


BookingHistoryPage.propTypes = {
	bookingHistory: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    bookingHistory: state.booking.bookingHistory,
	}
}

const mapDispatchToProps = {
  getBookingList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistoryPage);
