import fetch from 'node-fetch';
import history from '../history';
import cookie from "universal-cookie";
import moment from 'moment';

import cogoToast from 'cogo-toast';

const cookies = new cookie();
const currentServerURL = process.env.REACT_APP_serverURL;

var authObj = cookies.get('PMSAuthObj');
// console.log(moment(authObj.expiresAt).format('x'))
// console.log(new Date().getTime())
// console.log(moment(new Date().getTime() + 10000).format())
export default class Auth {

  constructor() {
    this.logout = this.logout.bind(this);
    this.hasSession = this.hasSession.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getName = this.getName.bind(this);
    this.getCompanyCode = this.getCompanyCode.bind(this);
    this.getUserCode = this.getUserCode.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  getDetails(token) {

    const endpoint = `${currentServerURL}/GetDetailsByToken`;
    const body = {
      "token": token
    }

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then((result) => {
        if (result) {
          this.setSession(token, result);
        } else {
          history.push(`/login`)
          cogoToast.error('Get details failed.')
        }
      })
      .catch((error) => {
        history.push(`/login`)
        cogoToast.error('Get details failed.')
      });
  }

  setSession(token, authResult) {
    cookies.set('PMSAuthObj',{
      accessToken: token,
      expiresAt: authResult.expiredon,
      // expiresAt: new Date().getTime() + 10000,
      userName: authResult.firstname + ' ' + authResult.lastname,
      email: authResult.email,
      usercode: authResult.usercode,
      companycode: authResult.companycode,
      canViewEn: authResult.canviewEng,
      canBook: authResult.canbook,
      isFulfillTeam: authResult.isfulfillteam,
    } , {path: '/'});

    if(window.location.pathname.includes('/login')) {
      window.location.href= '/'
    } else {
      window.location.reload()
    }
  }

  getAccessToken() {
    if(cookies.get('PMSAuthObj') != null && cookies.get('PMSAuthObj').accessToken != null) return cookies.get('PMSAuthObj').accessToken;
    return "";
  }

  getName() {
    if(cookies.get('PMSAuthObj') != null && cookies.get('PMSAuthObj').userName != null) return cookies.get('PMSAuthObj').userName;
    return "";
  }

  getCompanyCode() {
    if(cookies.get('PMSAuthObj') != null && cookies.get('PMSAuthObj').companycode != null) return cookies.get('PMSAuthObj').companycode;
    return "";
  }

  getUserCode() {
    if(cookies.get('PMSAuthObj') != null && cookies.get('PMSAuthObj').usercode != null) return cookies.get('PMSAuthObj').usercode;
    return "";
  }

  getUserDetail() {
    if(cookies.get('PMSAuthObj') != null) return cookies.get('PMSAuthObj');
    return "";
  }

  renewSession(needRefresh) {
    console.log('renewSession')

    if(authObj && authObj.email) {
      const endpoint = `${currentServerURL}/GetToken`;
      const body = {
        "loginemail": authObj.email,
      }

      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then((result) => {
          if (result.responseSuccess && result.token) {
            this.getDetails(result.token);
          } else {
            this.logout();
            cogoToast.error('Renew session error. Could not get a new token. You are logged out.')
          }
        })
        .catch((error) => {
          this.logout();
          cogoToast.error('Renew session error. You are logged out.', error)
        });
    } else {
      this.logout();
      alert(`Could not get a new token.`);
    }
  }

  logout() {
    cookies.remove('PMSAuthObj', { path: '/' });

    if (process.env.REACT_APP_env === 'pro') {
      window.location.href= 'https://www.adholidays.com/V5/V5/Home.aspx'
    } else {
      // history.push(`/login`)
      window.location.href= '/login'
    }
  }

  hasSession() {
    return cookies.get('PMSAuthObj') != null
  }

  isExpired() {
    // Check whether the current time > access token's expiry time

    if (cookies.get('PMSAuthObj').expiresAt) {
      if(moment().isBefore(cookies.get('PMSAuthObj').expiresAt)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

  }
}
