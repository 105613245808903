import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

// import bookingDetail from '../mock/bookingDetail.json'
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getBookingDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkg`;
    const body = {
      "token": auth.getAccessToken(),
      "ProductSysID": id,
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_DETAIL_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_DETAIL_SUCCESS,
            payload: result // bookingDetail // result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_DETAIL_FAILURE,
  					payload: null,
            msg: `Get booking detail failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get booking detail failed. API issue.`
				})
			});
	};
}

export function getBookingList(bookingStatus, activityId, product_id, keyword, productSySID, page) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RetrieveBkgList`;
    const body = {
      "token": auth.getAccessToken(),
      "bookingStatus": bookingStatus ? bookingStatus : '',
      "activityId": activityId ? activityId : '',
      "product_id": product_id ? product_id : '',
      "keywords": keyword ? keyword : '',
      "productSySID": productSySID,
      "page": page + 1,
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_HISTORY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_HISTORY_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_HISTORY_FAILURE,
  					payload: null,
            msg: `Get booking history failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_HISTORY_FAILURE,
					error,
					payload: null,
          msg: `Get booking history failed. API issue.`
				})
			});
	};
}

export function resendVoucher(productSysID, email) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/ResendVoucher`;
    const body = {
      "token": auth.getAccessToken(),
      "ProductSysID": productSysID,
      "ReceiverVoucherEmail": email
    }

		dispatch({
			type: ActionTypes.RESEND_VOUCHER_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.RESEND_VOUCHER_SUCCESS,
            payload: true,
            msg: `Resend voucher success.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.RESEND_VOUCHER_FAILURE,
  					payload: false,
            msg: `Resend voucher failed. ${result.responseMessage}`,
            result: false
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.RESEND_VOUCHER_FAILURE,
					error,
					payload: null,
          msg: `Resend voucher failed. API issue.`
				})
			});
	};
}

export function cancelBooking(productSysID) {
	return (dispatch) => {
		const endpoint = `${currentServerURL}/CancelBooking`;
		const body = {
		"token": auth.getAccessToken(),
		"ProductSysID": productSysID
		}

		dispatch({
			type: ActionTypes.CANCEL_BOOKING_REQUEST,
			body: body
		})

		fetch(endpoint, {
				method: 'POST',
		body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then(response => response.json())
				.then((result) => {

    			if (result.responseSuccess) {
      			dispatch({
      				type: ActionTypes.CANCEL_BOOKING_SUCCESS,
      				payload: true,
      				msg: `Cancel Booking success.`,
      			})
    			} else {
    		    dispatch({
    					type: ActionTypes.CANCEL_BOOKING_FAILURE,
    					payload: false,
      				msg: `Cancel Booking failed. ${result.responseMessage}`,
      				result: false
    				})
    			}
				})
				.catch((error) => {
					dispatch({
						type: ActionTypes.CANCEL_BOOKING_FAILURE,
						error,
						payload: null,
			msg: `Cancel Booking failed. API issue.`
					})
				});
		};
}

export function retrieveBkgHistory(productSysID) {
	return (dispatch) => {
		const endpoint = `${currentServerURL}/RetrieveBkgHistory`;
		const body = {
		"token": auth.getAccessToken(),
		"ProductSysID": productSysID
		}

		dispatch({
			type: ActionTypes.RETRIEVE_BOOKING_HISTORY_REQUEST,
			body: body
		})

		fetch(endpoint, {
				method: 'POST',
        body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then(response => response.json())
				.then((result) => {
    			if (result.responseSuccess) {
      			dispatch({
      				type: ActionTypes.RETRIEVE_BOOKING_HISTORY_SUCCESS,
      				payload: result.historylist
      			})
    			} else {
      			dispatch({
    						type: ActionTypes.RETRIEVE_BOOKING_HISTORY_FAILURE,
    						payload: null,
        				msg: `Retrieve booking history failed. ${result.responseMessage}`,
        				result: false
    					})
      			}
				})
				.catch((error) => {
					dispatch({
						type: ActionTypes.RETRIEVE_BOOKING_HISTORY_FAILURE,
						error,
						payload: null,
            msg: `Retrieve booking history failed. API issue.`
					})
				});
		};
}


// msg
export function getBookingMessage(id) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/GetBkgMessage/order`;
    const body = {
      "token": auth.getAccessToken(),
      "ProductSysID": id
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_BOOKING_MESSAGE_SUCCESS,
            payload: result.messageslist.reverse(),
            msg: `Get booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_MESSAGE_FAILURE,
  					payload: null,
            msg: `Get booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Get booking message failed. API issue.`
				})
			});
	};
}

export function createBookingMessage(orderId, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ManageMessage/NEW`;
    const body = {
      "token": auth.getAccessToken(),
    	"ProductSysID": orderId,
    	"Subject": form.subject,
    	"Detail": form.detail,
    }

		dispatch({
			type: ActionTypes.CREATE_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS,
            payload: true,
            msg: `Create booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Create booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Create booking message failed. API issue.`
				})
			});
	};
}

export function readBookingMessage(autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ManageMessage/READ`;
    const body = {
      "token": auth.getAccessToken(),
    	"Autonum": autonum
    }

		dispatch({
			type: ActionTypes.READ_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.READ_BOOKING_MESSAGE_SUCCESS,
            payload: true,
            msg: `Read booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.READ_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Read booking message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.READ_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Read booking message failed. API issue.`
				})
			});
	};
}

export function getUnreadBookingMessage() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/bookings/GetBkgMessage/unread`;
    const body = {
      "token": auth.getAccessToken(),
    }

		dispatch({
			type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.responseSuccess) {
          dispatch({
            type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_SUCCESS,
            payload: result.messageslist,
            msg: `Get unread booking message succeed.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_FAILURE,
  					payload: false,
            msg: `Get unread message failed. ${result.responseMessage}`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_UNREAD_BOOKING_MESSAGE_FAILURE,
					error,
					payload: null,
          msg: `Get unread message failed. API issue.`
				})
			});
	};
}
