// general
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export const GET_HOT_PRODUCT_REQUEST = 'GET_HOT_PRODUCT_REQUEST';
export const GET_HOT_PRODUCT_SUCCESS = 'GET_HOT_PRODUCT_SUCCESS';
export const GET_HOT_PRODUCT_FAILURE = 'GET_HOT_PRODUCT_FAILURE';

export const GET_HOT_LOCATION_REQUEST = 'GET_HOT_LOCATION_REQUEST';
export const GET_HOT_LOCATION_SUCCESS = 'GET_HOT_LOCATION_SUCCESS';
export const GET_HOT_LOCATION_FAILURE = 'GET_HOT_LOCATION_FAILURE';

export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';

export const GET_PRODUCT_TIMESLOT_REQUEST = 'GET_PRODUCT_TIMESLOT_REQUEST';
export const GET_PRODUCT_TIMESLOT_SUCCESS = 'GET_PRODUCT_TIMESLOT_SUCCESS';
export const GET_PRODUCT_TIMESLOT_FAILURE = 'GET_PRODUCT_TIMESLOT_FAILURE';

export const GET_PRODUCT_SKU_REQUEST = 'GET_PRODUCT_SKU_REQUEST';
export const GET_PRODUCT_SKU_SUCCESS = 'GET_PRODUCT_SKU_SUCCESS';
export const GET_PRODUCT_SKU_FAILURE = 'GET_PRODUCT_SKU_FAILURE';

export const CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE';

export const CLEAR_PRODUCT_DETAIL = 'CLEAR_PRODUCT_DETAIL';

// booking
export const GET_BOOKING_DETAIL_REQUEST = 'GET_BOOKING_DETAIL_REQUEST';
export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS';
export const GET_BOOKING_DETAIL_FAILURE = 'GET_BOOKING_DETAIL_FAILURE';

export const GET_BOOKING_HISTORY_REQUEST = 'GET_BOOKING_HISTORY_REQUEST';
export const GET_BOOKING_HISTORY_SUCCESS = 'GET_BOOKING_HISTORY_SUCCESS';
export const GET_BOOKING_HISTORY_FAILURE = 'GET_BOOKING_HISTORY_FAILURE';

export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const RETRIEVE_BOOKING_HISTORY_REQUEST = 'RETRIEVE_BOOKING_HISTORY_REQUEST';
export const RETRIEVE_BOOKING_HISTORY_SUCCESS = 'RETRIEVE_BOOKING_HISTORY_SUCCESS';
export const RETRIEVE_BOOKING_HISTORY_FAILURE = 'RETRIEVE_BOOKING_HISTORY_FAILURE';

export const ADD_BOOKING_HISTORY_REQUEST = 'ADD_BOOKING_HISTORY_REQUEST';
export const ADD_BOOKING_HISTORY_SUCCESS = 'ADD_BOOKING_HISTORY_SUCCESS';
export const ADD_BOOKING_HISTORY_FAILURE = 'ADD_BOOKING_HISTORY_FAILURE';

// booking -- message
export const GET_BOOKING_MESSAGE_REQUEST = 'GET_BOOKING_MESSAGE_REQUEST';
export const GET_BOOKING_MESSAGE_SUCCESS = 'GET_BOOKING_MESSAGE_SUCCESS';
export const GET_BOOKING_MESSAGE_FAILURE = 'GET_BOOKING_MESSAGE_FAILURE';

export const CREATE_BOOKING_MESSAGE_REQUEST = 'CREATE_BOOKING_MESSAGE_REQUEST';
export const CREATE_BOOKING_MESSAGE_SUCCESS = 'CREATE_BOOKING_MESSAGE_SUCCESS';
export const CREATE_BOOKING_MESSAGE_FAILURE = 'CREATE_BOOKING_MESSAGE_FAILURE';

export const READ_BOOKING_MESSAGE_REQUEST = 'READ_BOOKING_MESSAGE_REQUEST';
export const READ_BOOKING_MESSAGE_SUCCESS = 'READ_BOOKING_MESSAGE_SUCCESS';
export const READ_BOOKING_MESSAGE_FAILURE = 'READ_BOOKING_MESSAGE_FAILURE';

export const GET_UNREAD_BOOKING_MESSAGE_REQUEST = 'GET_UNREAD_BOOKING_MESSAGE_REQUEST';
export const GET_UNREAD_BOOKING_MESSAGE_SUCCESS = 'GET_UNREAD_BOOKING_MESSAGE_SUCCESS';
export const GET_UNREAD_BOOKING_MESSAGE_FAILURE = 'GET_UNREAD_BOOKING_MESSAGE_FAILURE';

// location
export const GET_ALL_LOCATION_REQUEST = 'GET_ALL_LOCATION_REQUEST';
export const GET_ALL_LOCATION_SUCCESS = 'GET_ALL_LOCATION_SUCCESS';
export const GET_ALL_LOCATION_FAILURE = 'GET_ALL_LOCATION_FAILURE';

export const GET_PRODUCT_BY_CITY_REQUEST = 'GET_PRODUCT_BY_CITY_REQUEST';
export const GET_PRODUCT_BY_CITY_SUCCESS = 'GET_PRODUCT_BY_CITY_SUCCESS';
export const GET_PRODUCT_BY_CITY_FAILURE = 'GET_PRODUCT_BY_CITY_FAILURE';

export const GET_PRODUCT_BY_COUNTRY_REQUEST = 'GET_PRODUCT_BY_COUNTRY_REQUEST';
export const GET_PRODUCT_BY_COUNTRY_SUCCESS = 'GET_PRODUCT_BY_COUNTRY_SUCCESS';
export const GET_PRODUCT_BY_COUNTRY_FAILURE = 'GET_PRODUCT_BY_COUNTRY_FAILURE';

export const RESEND_VOUCHER_REQUEST = 'RESEND_VOUCHER_REQUEST';
export const RESEND_VOUCHER_SUCCESS = 'RESEND_VOUCHER_SUCCESS';
export const RESEND_VOUCHER_FAILURE = 'RESEND_VOUCHER_FAILURE';

// msg
export const CLEAR_MSG = 'CLEAR_MSG';
export const CLEAR_ALL_MSG = 'CLEAR_ALL_MSG';
