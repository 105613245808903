import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const Loading = (props) => {
	return (
		<div className={`loading-wrapper ${props.isAbsolute ? 'absolute' : ''}`}>
		  <div className="loading__icon">
				<FontAwesomeIcon icon={faSpinner} size="3x" spin />
			</div>
		</div>
  );
}

Loading.propTypes = {
	isAbsolute: PropTypes.bool
};

export default Loading;
