import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	locationList: null,
	productList: null,
	countryInfo: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_ALL_LOCATION_SUCCESS:
	case ActionTypes.GET_ALL_LOCATION_FAILURE:
		return Object.assign({}, state, {
			locationList: action.payload,
		});
	case ActionTypes.GET_PRODUCT_BY_CITY_SUCCESS:
	case ActionTypes.GET_PRODUCT_BY_CITY_FAILURE:
		return Object.assign({}, state, {
			productList: action.payload,
		});
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_SUCCESS:
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_FAILURE:
		return Object.assign({}, state, {
			countryInfo: action.payload,
		});
	default:
		return state;
	}
};

export default product;
