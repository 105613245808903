import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

const AlertText = (props) => {
	return (
		<span className="text-danger ml-2"><FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />{props.text}</span>
  );
}

AlertText.propTypes = {
	text: PropTypes.string,
}

export default AlertText;
