import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';

const initialState = {
	msg: null,
	isError: false,
	timestamp: moment().valueOf()
};

const msg = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.RESEND_VOUCHER_SUCCESS:
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
	case ActionTypes.ADD_BOOKING_HISTORY_SUCCESS:
	case ActionTypes.CREATE_BOOKING_MESSAGE_SUCCESS:
		return {
			msg: action.msg,
			isError: false,
			timestamp: moment().valueOf()
		};
	case ActionTypes.LOGIN_FAILURE:
	case ActionTypes.GET_HOT_PRODUCT_FAILURE:
	case ActionTypes.GET_HOT_LOCATION_FAILURE:
	case ActionTypes.GET_PRODUCT_DETAIL_FAILURE:
	case ActionTypes.GET_PRODUCT_TIMESLOT_FAILURE:
	case ActionTypes.GET_PRODUCT_SKU_FAILURE:
	case ActionTypes.CREATE_BOOKING_FAILURE:
	case ActionTypes.GET_BOOKING_DETAIL_FAILURE:
	case ActionTypes.GET_BOOKING_HISTORY_FAILURE:
	case ActionTypes.CANCEL_BOOKING_FAILURE:
	case ActionTypes.GET_ALL_LOCATION_FAILURE:
	case ActionTypes.GET_PRODUCT_BY_CITY_FAILURE:
	case ActionTypes.GET_PRODUCT_BY_COUNTRY_FAILURE:
	case ActionTypes.RESEND_VOUCHER_FAILURE:
	case ActionTypes.RETRIEVE_BOOKING_HISTORY_FAILURE:
	case ActionTypes.ADD_BOOKING_HISTORY_FAILURE:
	case ActionTypes.GET_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.CREATE_BOOKING_MESSAGE_FAILURE:
	case ActionTypes.GET_UNREAD_BOOKING_MESSAGE_FAILURE:
		return {
			msg: action.msg,
			isError: true,
			timestamp: moment().valueOf()
		};

	default:
		return state;
	}
};

export default msg;
