import {combineReducers} from 'redux';
// import * as ActionTypes from '../constants/ActionTypes';

// Import the actions types constant we defined in our actions
import loading from './loading';
import msg from './msg';
import product from './product';
import booking from './booking';
import location from './location';

const initialState = {
	// countryCodeList: [],
};

const index = (state = initialState, action) => {
	switch (action.type) {
	default:
		return state;
	}
};

// Combine all the reducers
const rootReducer = combineReducers({
  index,
  loading,
	msg,
	product,
	booking,
	location,
})

export default rootReducer;
